import React, { useEffect, useState } from "react";
import { DRSSummary } from "./summary";
import DRSTable from "./drsTable";
import CloseDrs from "./closedrs";
import { summaryCurrentDRS, summaryDRSStatus } from "./common/columns";
import { fetchShipment, validateFe } from "./common/services";
import { Spin, Empty } from "antd";
import "./index.scss";
import _ from "lodash";
import { notifyApiError } from "../../../common/utils/utils";
import { useSelector } from "react-redux";
import MonitoringReportsService from "../../../services/monitoring-report/monitoring-report.service";
import { Constants } from "../../../common/utils/constants";
import MetabaseWidget from "../../metabase-dashboards/components/metabase.widget";

function DRSDetailed(props) {
    const { userId, setDrsId, drsId, showCloseDrs, setCloseDrs, userInfo,
        setDrsSummary, setDrsStatus } = props;
    const [drs, setDrs] = useState({});
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        "pageNo": 1,
        "pageSize": 10,
    })
    const config = useSelector((state) => state?.app?.configurations)

    useEffect(() => {

        if (userId) {

            setLoading(true)

            validateFe(userId)
                .then((data) => {
                    if (data) {
                        let resDataCopy = { ...data }
                        resDataCopy['drs'] = {
                            'sellerData': data['sellerConsignments']
                        }

                        setDrs(resDataCopy)
                        setDrsId(data?.rtoDrsId)
                        setDrsStatus(data?.rtoDrsStatus)
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                    notifyApiError(error?.errors ? error.errors : error, 'FAILED')
                    console.error("validateFe api failed with the following error -> ", error)
                })
        }
    }, [userId, filters]);

    return (
        <>
            <Spin spinning={loading}>
                {
                    (drs?.rtoDrsStatus != "CLOSED" && drsId) ?
                        <>

                            <div className="drs-detailed white-bg flex-column flex-gap-l spacer-m border-radius-s">
                                <div className="drs-detailed-title">
                                    <h3 className="icon-size-xl">DRS ID: <span className="drs-detailed-title_blue">{drs?.drs?.drsCode ? drs?.drs?.drsCode : drs?.rtoDrsCode}</span></h3>
                                </div>
                                <div className="flex-box flex-gap-l">
                                    <div className="width-half">
                                        <DRSSummary
                                            title="Summary of Current RTO DRS"
                                            columns={summaryCurrentDRS()}
                                            dataSource={drs?.drsSummary}
                                        />
                                    </div>
                                    <div className="width-half">
                                        <DRSSummary
                                            title="RTO DRS status"
                                            columns={summaryDRSStatus()}
                                            dataSource={drs?.drsSummary}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <DRSTable
                                        data={drs}
                                        setFilters={setFilters}
                                    />
                                </div>


                            </div>
                            {showCloseDrs &&
                                <div>
                                    <CloseDrs
                                        userInfo={userInfo}
                                        visible={showCloseDrs}
                                        close={setCloseDrs}
                                        data={drs} />
                                </div>
                            }

                        </>
                        :
                        <div className="white-bg" style={{ height: "calc(100vh - 300px)", marginBottom: 25 }}>
                            <MetabaseWidget text={"OPEN RTO-DRS Summary - FE Level report"}
                                className={"flex-1 flex-box justify-content-center"}
                                reportJson={{
                                    questionNo: 4866, reportName: "open-rto-drs-summary-fe-level-report",
                                    lockedParams: ["location_id", "partner_id"], height: "400px"
                                }} />
                        </div>
                    // <Empty description="No Open DRS for this FE"/>
                }
            </Spin>
        </>

    )
}

export default DRSDetailed;