import { ACTION_TYPES, INITIAL_STATE } from "./constants";

export const rtoDrsReducer = (state = INITIAL_STATE, action = {}) => {
    switch(action.type){
        case ACTION_TYPES.FETCH_DRS_LIST_BEGIN:
            return {
                ...state,
                rtoDrsTableLoader: true
            }
        case ACTION_TYPES.FETCH_SPARK_LINE_DATA__BEGIN:
            return {
                ...state,
                loading: true
            };
        case ACTION_TYPES.FETCH_DRS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                newDrsData: action.payload.drses,
                ...action.payload,
                rtoDrsTableLoader: false
            };
        case ACTION_TYPES.FETCH_DRS_LIST_FAILURE:
        case ACTION_TYPES.FETCH_SPARK_LINE_DATA__FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                rtoDrsTableLoader: false
            };
        case ACTION_TYPES.FETCH_SPARK_LINE_DATA__SUCCESS:
            return {
                ...state,
                loading: false,
                dataCardList: action.payload
            }
        case ACTION_TYPES.UPDATE_RTO_DRS_CODE :
            return {
                ...state,
                loading: false,
                drsFormData : {
                    ...state.drsFormData,
                    drsCode : action.drsCode
                }
            }
        case ACTION_TYPES.FETCH_FE_EMPLOYEES_RTO : 
            return {
                ...state,
                feUsers : action.payload
            }
        case ACTION_TYPES.FETCH_CUSTOMER_LOCATION : 
            return {
                ...state,
                pickupLocations : action.payload
            }
        case ACTION_TYPES.UPDATE_RTO_DRS_FORMDATA : 
            return {
                ...state,
                drsFormData : action.data
            }
        case ACTION_TYPES.RTO_DRS_PENDING_WAYBILL : 
            return {
                ...state,
                pendingWaybills : action.data
            }
        case ACTION_TYPES.RTO_DRS_SELECTED_WAYBILL : 
            return {
                ...state,
                selectedWaybills : action.data
            }

        case ACTION_TYPES.RTO_DRS_WAYBILLS : 
            return {
                ...state,
                selectedWaybills : action.selectedWaybills,
                pendingWaybills  : action.pendingWaybills
            }
        default:
            return state;
    }
};
