import { Button, Icon, Modal, Spin, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import formSchema from "./form.schema";
import FormWrapper from "../../ui/formComponent/form";
import { getFEUsers, fetchFeList } from "../../rto/rto-drs/services/rto.drs.service";
import { useSelector } from "react-redux";

export default function DRSFilters({ onFormSubmit }) {

    var formRef = useRef();

    const appConfig = useSelector(state => state?.app?.configurations)

    const defaultFilterOptions = {
        drsStatuses: ['PENDING', 'IN_TRANSIT', 'HANDED_OVER', 'COMPLETED', 'DRAFT']
    }

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterOptions, setFilterOptions] = useState(defaultFilterOptions);
    const [loading, SetLoading] = useState(true);
    const [formSchemaData, setFormSchemaData] = useState();

    /* TODO: Design a new select dropdown component to allow empty select dropdown, 
    to bypass default select enum validation */
    useEffect(() => {
        if (showFilterModal === true) {
            if (true) {
                fetchFeList()
                    .then(resp => {
                        const userIds = []
                        const userName = []

                        resp?.userDetails.forEach((userInfo) => {
                            userIds.push(userInfo.id)
                            userName.push(userInfo.name)
                        })

                        if (userIds.length > 0) {
                            formSchema.schema.properties.drsUserIds = {
                                ...formSchema.schema.properties.drsUserIds,
                                enum: userIds,
                                enumNames: userName
                            };
                        } else {
                            delete formSchema.schema.properties.drsUserIds
                        }

                        SetLoading(false)
                    })
                    .catch((e) => {
                        SetLoading(false)
                        console.error('fetchFeList failed with error -> ' + e)
                    })
            }
            else {
                getFEUsers().then(response => {
                    if (response?.enum?.length > 0) {
                        formSchema.schema.properties.drsUserIds = {
                            ...formSchema.schema.properties.drsUserIds,
                            ...response
                        };
                    } else {
                        delete formSchema.schema.properties.drsUserIds
                    }
                    SetLoading(false)
                }).catch(_ => SetLoading(false))
            }

            setFormSchemaData(formSchema)

        }
    }, [showFilterModal])

    const onSubmit = data => {
        setShowFilterModal(false);
        onFormSubmit({
            ...data,
            drsCodes: data.drsCodes ? data.drsCodes.trim().split(",") : [],
            createdAt: data.createdAt ? { from: data.createdAt.split(",")[0], to: data.createdAt.split(",")[1] } : undefined
        });
    }

    const resetFilters = _ => {
        setShowFilterModal(false);
        setFilterOptions(defaultFilterOptions);
        onFormSubmit({ ...defaultFilterOptions }, true);
    }

    return (
        <div className="flex-box flex-gap-l">
            <Tooltip placement="top" title="Reload DRS List">
                <Button size="large" onClick={resetFilters} type="primary">
                    <Icon type="reload" />
                </Button>
            </Tooltip>
            <Button size="large" onClick={_ => setShowFilterModal(true)}
                icon="filter" className="lsn-btn-secondary">
                Filter
            </Button>
            {
                showFilterModal &&
                <Modal visible={showFilterModal} title={"Filter"} width={500} closable={true} maskClosable={false}
                    onCancel={_ => setShowFilterModal(false)}
                    footer={
                        <div className="flex-box flex-gap-l justify-content-flex-end align-items-center">
                            <Button className="lsn-btn-secondary" onClick={resetFilters} > Reset </Button>
                            <Button type="primary" className="lsn-btn-primary"
                                onClick={_ => formRef.current.formReference.current.submit()} > Apply </Button>
                        </div>
                    }>
                    <Spin spinning={loading}>
                        {
                            !loading &&
                            <FormWrapper {...formSchemaData} formData={filterOptions} onChange={setFilterOptions}
                                hideSubmit={true} onSubmit={onSubmit} ref={formRef} />
                        }
                    </Spin>
                </Modal>
            }
        </div>
    )
}