import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const ShipmentDiscrepancy = () => {

    return (
        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">Shipment Discrepancy at a Location | RCA</div>

            <div className="flex-column flex-gap-xl">

                <MetabaseWidget text={"Shipment Discrepancy at a Location"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2717, reportName: "shipment-discrepancy-at-a-location",
                        lockedParams: ["location_id", "location_alias"], height: "400px"
                    }} />

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"Overage Shipments | Datewise"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 2715, reportName: "overage-shipments-datewise",
                            lockedParams: ["location_id", "location_alias"], height: "400px"
                        }} />
                    <MetabaseWidget text={"Shortage Shipments | Datewise"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 2716, reportName: "shortage-shipments-datewise",
                            lockedParams: ["location_id", "location_alias"], height: "400px"
                        }} />
                </div>
            </div>
        </div>
    )

}

export default ShipmentDiscrepancy;