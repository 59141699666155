import { Button, Icon, Spin } from "antd";
import { Component, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MonitoringReportsService from "../../../services/monitoring-report/monitoring-report.service";
import { notifyApiError } from "../../../common/utils/utils";
import ScHubVisibilityForwardDashboard from "../../metabase-dashboards/sort-center/ScDailyActions";
import RtoWaybill from "../../metabase-dashboards/rto/rto-waybill";

const monitoringReportsService = new MonitoringReportsService();

export default function MetabaseReport({ reportName, autoRefresh, autoRefreshTimetout,
    hideBackButton, iframeHeight, dateParams, loadOnUserDemandObj, loadCompHeight }) {

    const [embedUrl, SetEmbedUrl] = useState(null);
    const [loading, SetLoading] = useState(false);
    const [showLoadDashboardBtn, setShowLoadDashboardBtn] = useState(true);
    const [iframeLoader, setIframeLoader] = useState(false)
    const navigate = useNavigate();

    const loadDashboardQuestionOnDemand = {
        "rto-waybill": { component: <RtoWaybill /> }
    }

    useEffect(() => {

        if (!loadOnUserDemandObj?.loadOnUserDemand && !loadDashboardQuestionOnDemand[reportName]) {
            loadDashboard()
        }
        SetEmbedUrl(null)
        setShowLoadDashboardBtn(true)

    }, [reportName])

    const loadDashboard = () => {

        if (!loadOnUserDemandObj?.loadOnUserDemand) {
            SetLoading(true)
        }

        monitoringReportsService.getEmbeddedURL(reportName)
            .then(res => {
                if (!loadOnUserDemandObj?.loadOnUserDemand) {
                    SetLoading(false)
                }

                if (!res?.data?.response?.embedUrl || res?.data?.response?.embedUrl.trim() === "") {
                    throw new Error(res?.data?.response?.statusMessage ? res?.data?.response?.statusMessage :
                        'Invalid embedded URL: null or undefined');
                }

                setIframeLoader(true)

                let newUrl = res?.data?.response?.embedUrl
                if (dateParams) {
                    let urlParams = ""
                    const formattedDate = new Date().toISOString().split('T')[0]
                    const [baseUrl, hashStringFormat] = res?.data?.response?.embedUrl.split('#')
                    if (dateParams.has("startDate")) { urlParams += `&start_date=${formattedDate}` }
                    if (dateParams.has("endDate")) { urlParams += `&end_date=${formattedDate}` }
                    newUrl = `${baseUrl}${urlParams != null ? '?' + urlParams : urlParams}${hashStringFormat ? "#" + hashStringFormat : ""}`
                }
                SetEmbedUrl(newUrl);
                if (loadOnUserDemandObj?.loadOnUserDemand) {
                    setShowLoadDashboardBtn(false)
                }

            })
            .catch(err => {
                console.error(err)
                notifyApiError(err?.message)
                if (!loadOnUserDemandObj?.loadOnUserDemand) {
                    SetLoading(false)
                } else {
                    setShowLoadDashboardBtn(false)
                }
                setIframeLoader(false)
                SetEmbedUrl(null)
            })

    }

    const iframeComp = () => {
        return (
            <>
                {
                    <>
                        {iframeLoader &&
                            <Spin spinning={true}></Spin>
                        }

                        {embedUrl &&
                            <iframe src={`${embedUrl}${autoRefresh ? `&refresh=${autoRefreshTimetout || 60}` : ""}`} frameBorder={0}
                                width="100%"
                                style={{ height: iframeHeight || "calc(100vh - 180px)", visibility: iframeLoader ? "hidden" : "visible" }}
                                onLoad={() => setIframeLoader(false)} />
                        }
                    </>


                }
            </>
        )
    }

    return (
        <>

            {reportName && loadDashboardQuestionOnDemand[reportName] ?
                <>
                    {loadDashboardQuestionOnDemand[reportName].component}
                </>
                :
                <>
                    {
                        loading ?
                            <Spin spinning={true} /> :
                            <div className={`white-bg flex-column flex-gap-s ${!hideBackButton ? "spacer-vs" : ""}`}>
                                {
                                    !hideBackButton &&
                                    <div className="cursor-pointer flex-box flex-gap-m align-items-center spacer-hm" onClick={_ => navigate(-1)}>
                                        <Icon type="arrow-left" style={{ fontSize: 18 }} />
                                    </div>
                                }
                                {loadOnUserDemandObj?.loadOnUserDemand && showLoadDashboardBtn ?
                                    <div className="flex-box flex-column align-items-center justify-content-center"
                                        style={{ height: (loadCompHeight ? loadCompHeight : '500px') }}
                                        onClick={() => loadDashboard()}>
                                        <h1>
                                            <a target='_blank' className="text-semi-bold">
                                                {loadOnUserDemandObj?.titleCardName && loadOnUserDemandObj?.titleCardName.trim() != "" ?
                                                    loadOnUserDemandObj.titleCardName : "Click here to load the Dashboard"}
                                            </a>
                                        </h1>
                                        <div className="font-size-m-3 grey-text">(Click to View)</div>
                                    </div>
                                    :
                                    <>{iframeComp()}</>
                                }

                            </div>
                    }
                </>
            }

        </>
    )
}