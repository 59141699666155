import moment from "moment";
import { apiRequest } from "../../../../common/utils/apiGateway";
import { checkHttpStatus } from "../../../../common/utils/utils";
import { apiService } from "../../../../common/apiService/service";

export const fetchSparkline = ({ locationIds, clusterNames, custIds }) => new Promise((resolve, reject) => {
    apiRequest({
        url    : '/b2c/drs/dashboard/sparkline',
        method : 'POST',
        ignoreStatus : true,
        data   : {
            clusterNames : locationIds && locationIds?.length > 0 ? undefined : clusterNames ? clusterNames.map(c => c.split("__").join(" ")) : undefined,
            locationIds  : locationIds,
            custIds      : custIds
        }
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response)
        }
        else {
            reject(response?.data?.status);
        }
    })
    .catch(e => reject(e))
}); 

export const fetchDRSData = ({ locationIds, pageNo, feIds, tags, clusterNames, custIds }) => new Promise((resolve, reject) => {
    apiRequest({
        url    : '/b2c/drs/dashboard',
        method : 'POST',
        ignoreStatus : true,
        data   : {
            filter: {
                clusterNames : locationIds && locationIds?.length > 0 ? undefined : clusterNames ? clusterNames.map(c => c.split("__").join(" ")) : undefined,
                locationIds  : locationIds,
                feIds        : feIds || [],
                tags         : tags  || [],
                custIds      : custIds || [],
                sortingOptions: [
                    {
                        columnName : "is_suspicious",
                        sortBy     : "IS_SUSPICOUS"
                    }
                ]
            },
            pageNo   : pageNo,
            pageSize : 10
        }
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response)
        }
        else {
            reject(response?.data?.status);
        }
    })
    .catch(e => reject(e))
})

export const fetchDRSConsignments = ({ drsId, shipmentStatus, pageNo, tags, custIds, feIds }) => new Promise((resolve, reject) => {
    apiRequest({
        url    : `/b2c/drs/dashboard/drs-view`,
        method : 'POST',
        ignoreStatus : true,
        data   : {
            pageNo   : pageNo,
            pageSize : 10,
            filter : {
                drsId,
                feIds,
                custIds,
                shipmentStatus,
                tags,
                sortOption: [
                    {
                        columnName : "is_suspicious",
                        sortBy     : "IS_SUSPICOUS",
                        sortOrder  : "DESC"
                    }
                ]
            }
        }
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response)
        }
        else {
            reject(response?.data?.status);
        }
    })
    .catch(e => reject(e))
})

export const fetchPendingSuspiciousConsignments = (pageNo) => new Promise((resolve, reject) => {
    apiRequest({
        url    : `/b2c/drs/dashboard/waybill-view`,
        method : 'POST',
        ignoreStatus : true,
        data   : {
            pageNo   : pageNo,
            pageSize : 10,
            filter : {
                startDate : moment().subtract(7, "days").startOf("day").valueOf(),
                endDate   : moment().endOf("day").valueOf()
            }
        }
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response)
        }
        else {
            reject(response?.data?.status);
        }
    })
    .catch(e => reject(e))
})

export const fetchDRSFilters = ({ locationIds }) => new Promise((resolve, reject) => {
    apiRequest({
        url    : `/b2c/drs/dashboard/filters`,
        method : 'POST',
        data   : {
            locationIds : locationIds
        }
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response)
        }
    })
    .catch(e => reject(e))
})

export const validateSuspicious = (params) => new Promise((resolve, reject) => {
    
    const postObj = { ...params };
    
    apiRequest({
        url    : "b2c/drs/dashboard/suspicious",
        method : 'POST',
        data   : postObj
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response)
        }
    })
    .catch(e => reject(e))
});

export const fetchSuspiciousWaybillData = (params) =>new Promise((resolve, reject) => {
    const postObj = { ...params };
    
    apiRequest({
        url    : "b2c/drs/dashboard/consolidated-view",
        method : 'POST',
        data   : postObj
    })
    .then(response => {
        if (checkHttpStatus(response?.data?.status)) {
            resolve(response?.data?.response)
        }
    })
    .catch(e => reject(e))
});

export const sendOTP = (payload) => {
    return apiService.post("lm/v1/drs/reject/otp", payload);
}

export const validateOtp = (payload) => {
    return apiService.post("lm/v1/drs/validate/reject/otp", payload);
}
