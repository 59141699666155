import { React, useEffect, useState } from 'react';
import { Card, Icon, Spin, version } from 'antd';
import '../form.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FormWidgets from '../../../ui/formComponent/formWidgets';
import FormWrapper from '../../../ui/formComponent/form';
import formSchema from './LocationOnboardingFormSchema';
import LocationService from '../../../../services/settings/location.service';
import { notifyApiError, notifyApiSuccess } from '../../../../common/utils/utils';
import CustomArrayFieldTemplate from '../components/CustomArrayField/CustomArrayFieldTemplate';
import { useParams } from "react-router-dom";

const LocationOnboardingForm = ({ storeEntityDetails, onSubmitSuccess,
    captureEntityData, locationDetails }) => {

    let formSchemaCopy = JSON.parse(JSON.stringify(formSchema))

    const params = useParams();
    const locationId = params?.id

    const locationService = new LocationService;
    const navigate = useNavigate();
    const [customerList, setCustomerList] = useState([]);
    const [partnerList, setPartnerList] = useState([]);
    const [formSchemaValue, setFormSchemaValue] = useState(null);
    const [formData, setFormData] = useState({});
    const [staffPayCities, setStaffPayCities] = useState([]);
    const [partnerIdToName, setPartnerIdToName] = useState({});
    const [customerIdToName, setCustomerIdToName] = useState({});

    useEffect(() => {
        loadInitialFormData()
    }, []);

    useEffect(() => {

        if (Number.isInteger(Number.parseInt(locationId))) {
            initializeDataForUpdateFlow()
        } else if (formSchemaCopy) {
            initializeDataForCreateFlow()
        }

    }, [partnerList, customerList, locationId, staffPayCities, locationDetails]);

    const initializeDataForCreateFlow = () => {

        if (partnerList) {
            let partnerIdToNameObj = {}
            // Extracting and assigning partner details
            var partnerName = []
            var partnerId = []
            partnerList.map(partner => {
                partnerName.push(partner.name)
                partnerId.push(partner.id)
                partnerIdToNameObj[partner.id] = partner.name
            })
            formSchemaCopy.schema.allOf[0].then.properties.existingPartnerSelect.enumNames = partnerName
            formSchemaCopy.schema.allOf[0].then.properties.existingPartnerSelect.enum = partnerId
            setPartnerIdToName(partnerIdToNameObj)
        }

        if (customerList) {
            // Extracting and assigning customer details
            var customerName = []
            var customerId = []
            customerList.map(customer => {
                customerName.push(customer.name)
                customerId.push(customer.id)
            })

            // Adding location client clientName.
            formSchemaCopy.schema.allOf[1].then.properties.location.properties.locationClient.properties.new.items.properties.clientName.enum = customerId
            formSchemaCopy.schema.allOf[1].then.properties.location.properties.locationClient.properties.new.items.properties.clientName.enumNames = customerName

            formSchemaCopy.schema.allOf[2].then.properties.location.properties.locationClient.properties.new.items.properties.clientName.enum = customerId
            formSchemaCopy.schema.allOf[2].then.properties.location.properties.locationClient.properties.new.items.properties.clientName.enumNames = customerName

            // Adding RVP location client clientName.
            formSchemaCopy.schema.allOf[2].then.properties.location.properties.rvpLocationClient.properties.new.items.properties.clientName.enum = customerId
            formSchemaCopy.schema.allOf[2].then.properties.location.properties.rvpLocationClient.properties.new.items.properties.clientName.enumNames = customerName

        }

        if (staffPayCities) {
            // Extracting and assigning cities
            var cityNames = []
            var cityIds = []
            staffPayCities.map((cityObj) => {
                cityNames.push(cityObj.name)
                cityIds.push(cityObj.id)
            })
            formSchemaCopy.schema.properties.location.properties.locationStaffPayCity.enum = cityIds
            formSchemaCopy.schema.properties.location.properties.locationStaffPayCity.enumNames = cityNames
        }
        // Setting form schema
        setFormSchemaValue(formSchemaCopy);

        //Setting initial form data
        const formData = { isExistingPartnerRadio: true, location: { isValmoLocation: false, locationOpsType: "LM" } }
        setFormData(formData)
    }

    const initializeDataForUpdateFlow = () => {

        // Extracting and assigning partner details
        var partnerName = []
        var partnerId = []
        partnerList.map(partner => {
            partnerName.push(partner.name)
            partnerId.push(partner.id)
        })
        formSchemaCopy.schema.allOf[0].then.properties.existingPartnerSelect.enumNames = partnerName
        formSchemaCopy.schema.allOf[0].then.properties.existingPartnerSelect.enum = partnerId

        // Extracting and assigning customer details
        var customerName = []
        var customerId = []

        let clientDetails = []
        if (locationDetails?.customer && locationDetails.customer.length > 0) {
            locationDetails.customer.map((customer) => {
                let clientData = {}
                clientData["clientName"] = customer.id
                clientData["clientPickupPincodes"] = customer.pickupPincodes.join("\n")
                clientDetails.push(clientData)
            })
        }

        const customerIdToNameObj = {}

        customerList.map(customer => {
            customerName.push(customer.name)
            customerId.push(customer.id)
            customerIdToNameObj[customer.id] = customer.name
        })
        setCustomerIdToName(customerIdToNameObj)

        //Changing form ui schema
        delete formSchemaCopy.schema.properties.isExistingPartnerRadio
        delete formSchemaCopy.schema.properties.location.properties.locationStaffPayCity
        delete formSchemaCopy.schema.properties.location.properties.clientLocationName
        delete formSchemaCopy.schema.properties.branchUser
        delete formSchemaCopy.schema.properties.location.properties.isValmoLocation

        formSchemaCopy.schema.properties.location.required = ["locationName", "locationAddress", "locationPincode", "locationOpsType"],

            formSchemaCopy.schema.properties["existingPartnerSelect"] = {
                "title": "Selected Partner",
                "type": "integer",
                "enumNames": partnerName,
                "enum": partnerId,
                "searchEnabled": true
            }

        const existingClientDataSchema = {
            "title": "",
            "type": "array",
            "items": {
                "required": ["clientName", "clientPickupPincodes", "isSelfServed"],
                "properties": {
                    "clientName": {
                        "title": "Name",
                        "type": "integer",
                        "enum": customerId,
                        "enumNames": customerName,
                        "searchEnabled": true
                    },
                    "isSelfServed": {
                        "type": "boolean",
                        "title": "Is self served ?"
                    },
                    "clientPickupPincodes": {
                        "title": "Pickup Pincodes (Seller pincode)",
                        "type": "string"
                    }
                }
            }
        }

        if (locationDetails?.locationOpsType === "LM") {
            existingClientDataSchema.title = "Existing Client Pickup pincodes"
            formSchemaCopy.schema.allOf[2].then.properties.location.required = []
            formSchemaCopy.schema.allOf[2].then.properties.location.properties.locationClient.title = "Client pickup pincodes"
            formSchemaCopy.schema.allOf[2].then.properties.location.properties.locationClient.properties.new.title = "New Client pickup pincodes"
            formSchemaCopy.schema.allOf[2].then.properties.location.properties.locationClient.properties.new.items.properties.clientName.enum = customerId
            formSchemaCopy.schema.allOf[2].then.properties.location.properties.locationClient.properties.new.items.properties.clientName.enumNames = customerName
            if (locationDetails?.customer && locationDetails?.customer.length > 0) {
                formSchemaCopy.schema.allOf[2].then.properties.location.properties.locationClient.properties = {
                    "existing": { ...existingClientDataSchema },
                    ...formSchemaCopy.schema.allOf[2].then.properties.location.properties.locationClient.properties
                }
            }

            existingClientDataSchema.title = "Existing RVP Client Pickup pincodes"
            formSchemaCopy.schema.allOf[2].then.properties.location.properties.rvpLocationClient.title = "RVP client pickup pincodes"
            formSchemaCopy.schema.allOf[2].then.properties.location.properties.rvpLocationClient.properties.new.title = "New RVP client pickup pincodes"
            formSchemaCopy.schema.allOf[2].then.properties.location.properties.rvpLocationClient.properties.new.items.properties.clientName.enum = customerId
            formSchemaCopy.schema.allOf[2].then.properties.location.properties.rvpLocationClient.properties.new.items.properties.clientName.enumNames = customerName
            if (locationDetails?.rvpCustomer && locationDetails?.rvpCustomer.length > 0) {
                formSchemaCopy.schema.allOf[2].then.properties.location.properties.rvpLocationClient.properties = {
                    "existing": { ...existingClientDataSchema },
                    ...formSchemaCopy.schema.allOf[2].then.properties.location.properties.rvpLocationClient.properties
                }
            }
        }

        if (locationDetails?.locationOpsType === "FM") {
            existingClientDataSchema.title = "Existing Client Pickup pincodes"
            formSchemaCopy.schema.allOf[1].then.properties.location.properties.locationClient.title = "Client pickup pincodes"
            formSchemaCopy.schema.allOf[1].then.properties.location.properties.locationClient.properties.new.title = "New Client pickup pincodes"
            formSchemaCopy.schema.allOf[1].then.properties.location.properties.locationClient.properties.new.items.properties.clientName.enum = customerId
            formSchemaCopy.schema.allOf[1].then.properties.location.properties.locationClient.properties.new.items.properties.clientName.enumNames = customerName
            if (locationDetails?.customer && locationDetails?.customer.length > 0) {
                formSchemaCopy.schema.allOf[1].then.properties.location.properties.locationClient.properties = {
                    "existing": { ...existingClientDataSchema },
                    ...formSchemaCopy.schema.allOf[1].then.properties.location.properties.locationClient.properties
                }
            }
        }

        //updating ui:schema
        formSchemaCopy.uiSchema.existingPartnerSelect = {
            "ui:readonly": true,
            "classNames": "existing-partner-select-input"
        }

        formSchemaCopy.uiSchema.location.locationPincode["ui:readOnly"] = true
        formSchemaCopy.uiSchema.location.locationOpsType["ui:readonly"] = true

        // Formatting client data
        const clientInfoList = []
        if (locationDetails?.customer && locationDetails?.customer.length > 0) {
            locationDetails.customer.map((customerData) => {
                const clientData = {}
                clientData["clientName"] = customerData.id
                clientData["clientPickupPincodes"] = customerData.pickupPincodes.join("\n")
                clientData["isSelfServed"] = customerData.isSelfServed
                clientInfoList.push(clientData)
            })
        }

        // Formatting rvp client data
        const rvpClientInfoList = []
        if (locationDetails?.rvpCustomer && locationDetails?.rvpCustomer.length > 0) {
            locationDetails.rvpCustomer.map((customerData) => {
                const clientData = {}
                clientData["clientName"] = customerData.id
                clientData["clientPickupPincodes"] = customerData.pickupPincodes.join("\n")
                clientData["isSelfServed"] = customerData.isSelfServed
                rvpClientInfoList.push(clientData)
            })
        }

        const formDataSet = {
            "isExistingPartnerRadio": true,
            "existingPartnerSelect": locationDetails?.partnerId,
            "location": {
                "locationName": locationDetails?.name,
                "locationAddress": locationDetails?.addressText,
                "locationPincode": locationDetails?.locPincode,
                "locationOpsType": locationDetails?.locationOpsType,
                "locationGstNumber": locationDetails?.gstNumber
            }
        }

        if (locationDetails?.locationOpsType === "LM" && locationDetails?.deliveryPincodes) {
            formDataSet.location["locationDeliveryPincode"] = locationDetails.deliveryPincodes.join("\n");
        }

        if (clientInfoList.length > 0) {
            formDataSet.location["locationClient"] = {
                "existing": clientInfoList
            }
        }

        if (rvpClientInfoList.length > 0) {
            formDataSet.location["rvpLocationClient"] = {
                "existing": rvpClientInfoList
            }
        }

        setFormSchemaValue(formSchemaCopy)
        setFormData(formDataSet)
    }

    const loadInitialFormData = () => {
        // Triggering initial required apis
        fetchPartnerList()
        fetchCustomerList()
        fetchStaffPayCities()

    }

    const fetchStaffPayCities = () => {
        locationService.fetchStaffPayCities()
            .then((data) => {
                if (data?.status?.code == 202) {
                    setStaffPayCities(data?.response?.cities)
                } else {
                    notifyApiError("Internal Server Error " + data?.status?.message)
                }
            })
            .catch((error) => {
                notifyApiError(error)
            })
    }

    const fetchPartnerList = () => {
        locationService.getPartnersList()
            .then(res => {
                if (res?.status?.code == 202) {
                    setPartnerList(res?.response?.partnersList)
                } else {
                    console.error("fetchPartnerList failed", res?.status?.message)
                    notifyApiError("Internal Server Error " + res?.status?.message)
                }
            })
            .catch(error => notifyApiError(error));
    }

    const fetchCustomerList = () => {
        locationService.getCustomerList()
            .then((res) => {
                if (res?.status?.code == 202) {
                    setCustomerList(res?.response?.customers)
                } else {
                    console.error("fetchCustomerList failed", res?.status?.message)
                    notifyApiError("Internal Server Error " + res?.status?.message)
                }
            })
            .catch(error => notifyApiError(error));
    }

    const onSubmit = fromDataSet => {
        if (Number.isInteger(Number.parseInt(locationId))) {
            triggerApiUpdateFlow(fromDataSet)
        } else {
            triggerApiCreateFlow(fromDataSet)
        }
    }

    const validate = (formData, errors) => {
        let phoneNumberPattern = /^[0-9]{10}$/;
        let emailIdPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let userNameRegex = /^[a-zA-Z() ]*$/
        let partnerNameRegex = /^[a-zA-Z0-9 ]{1,80}$/

        if (formData?.partner?.partnerName && !partnerNameRegex.test(formData.partner.partnerName)) {
            errors.partner.partnerName.addError("Invalid partner name!");
        }

        if (formData?.partner?.partnerPhoneNumber && !phoneNumberPattern.test(formData.partner.partnerPhoneNumber)) {
            errors.partner.partnerPhoneNumber.addError("Invalid phone number!");
        }

        // Validating user name...
        if (formData?.user?.userName && !userNameRegex.test(formData.user.userName)) {
            errors.user.userName.addError("Invalid user name")
        }

        // Validating user phone number...
        if (formData?.user?.userPhoneNumber && !phoneNumberPattern.test(formData.user.userPhoneNumber)) {
            errors.user.userPhoneNumber.addError("Invalid phone number!")
        }

        // Validating user email...
        if (formData?.user?.email && !emailIdPattern.test(formData.user.email)) {
            errors.user.email.addError("Invalid email format");
        }

        validateBranchUser(errors, formData, userNameRegex, emailIdPattern, phoneNumberPattern)

        if (formData?.location) {
            let singleLocFormData = formData?.location;
            if (singleLocFormData.locationOpsType == "FM" &&
                !singleLocFormData.locationClient?.new &&
                singleLocFormData.locationClient?.new?.length == 0 &&
                !singleLocFormData.locationClient?.existing &&
                singleLocFormData.locationClient?.existing?.length == 0) {
                errors.location.locationClient.addError("Location client required for FM location")
            }
            locationDetailsValidationCheck(singleLocFormData, errors);
        }

        return errors;
    }

    const validateBranchUser = (errors, formData, userNameRegex, emailIdPattern, phoneNumberPattern) => {

        // Checking if user phone number is present, if only user name is entered...
        if (formData?.branchUser?.userName && !formData?.branchUser?.userPhoneNumber) {
            errors.branchUser.userPhoneNumber.addError("Branch user phone number required!")
        }

        // Checking if user name is present, if only user phone number is entered...
        if (formData?.branchUser?.userPhoneNumber && !formData?.branchUser?.userName) {
            errors.branchUser.userName.addError("Branch user name required!")
        }

        // Checking if user name, user phone number is present, if only email is entered...
        if (formData?.branchUser?.email &&
            (!formData?.branchUser?.userName || !formData?.branchUser?.userPhoneNumber)) {
            if (!formData?.branchUser?.userName) {
                errors.branchUser.userName.addError("Branch user name required!")
            }

            if (!formData?.branchUser?.userPhoneNumber) {
                errors.branchUser.userPhoneNumber.addError("Branch user phone number required!")
            }
        }

        // Validating branch user name...
        if (formData?.branchUser?.userName && !userNameRegex.test(formData.branchUser.userName)) {
            errors.branchUser.userName.addError("Invalid branch user name")
        }

        // Validating branch user phone number details...
        if (formData?.branchUser?.userPhoneNumber && !phoneNumberPattern.test(formData.branchUser.userPhoneNumber)) {
            errors.branchUser.userPhoneNumber.addError("Invalid branch user phone number")
        }

        // Validating branch user email details...
        if (formData?.branchUser?.email && !emailIdPattern.test(formData.branchUser.email)) {
            errors.branchUser.email.addError("Invalid branch user mail id")
        }

        // Validating if admin user and branch user phone number is same...
        if (formData?.user?.userPhoneNumber && formData?.branchUser?.userPhoneNumber &&
            formData.user.userPhoneNumber === formData.branchUser.userPhoneNumber) {
            errors.branchUser.userPhoneNumber.addError("User phone number and branch user phone number cannot be same")
        }

    }

    const locationDetailsValidationCheck = (singleLocFormData, errors) => {

        const pincodePattern = /^[0-9]{6}$/
        const nameRegex = /^(?=[a-zA-Z])[a-zA-Z\s0-9()\/_-]{3,30}$/;
        const addressRegex = /^#?(?=[a-zA-Z0-9])[a-zA-Z\s0-9()"\/_.{}:[\]#@&|*,-]{9,250}$/;
        const gstNumberRegex = /^(?:([0][1-9]|[1-2][0-9]|[3][0-8])[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})?$/;
        const clientLocationNameRegex = /^[a-zA-Z0-9 ]{1,10}$/

        // Validating location name
        if (singleLocFormData?.locationName && !nameRegex.test(singleLocFormData.locationName)) {
            errors.location.locationName.addError("Invalid location name");
        }

        //Validating client location name
        if (singleLocFormData?.clientLocationName && !clientLocationNameRegex.test(singleLocFormData.clientLocationName)) {
            errors.location.clientLocationName.addError("Invalid client location name")
        }

        // Check for location pincode
        if (singleLocFormData?.locationPincode && !pincodePattern.test(singleLocFormData.locationPincode)) {
            errors.location.locationPincode.addError("Invalid pincode");
        }

        // Check for location GST number
        if (singleLocFormData?.locationGstNumber && !gstNumberRegex.test(singleLocFormData?.locationGstNumber)) {
            errors.location.locationGstNumber.addError("Invalid GST number");
        }

        // Validating location address
        if (singleLocFormData?.locationAddress && !addressRegex.test(singleLocFormData?.locationAddress)) {
            errors.location.locationAddress.addError("Invalid Address");
        }

        // Check for delivery pincode
        if (singleLocFormData?.locationDeliveryPincode) {

            const invalidDeliveryPincodes = []

            let deliveryPincodeList = singleLocFormData?.locationDeliveryPincode &&
                singleLocFormData.locationDeliveryPincode.indexOf(',') > -1 ?
                singleLocFormData.locationDeliveryPincode.split(",") :
                singleLocFormData.locationDeliveryPincode.split("\n");

            deliveryPincodeList.map(zipcode => {
                if (!pincodePattern.test(zipcode.trim())) {
                    invalidDeliveryPincodes.push(zipcode);
                }
            })

            if (invalidDeliveryPincodes.length > 0) {
                errors.location.locationDeliveryPincode.addError("Invalid pincode -> " + invalidDeliveryPincodes.join(', '));
            }
        }

        validateClientData(singleLocFormData, "locationClient", errors)
        validateClientData(singleLocFormData, "rvpLocationClient", errors)

    }

    const validateClientData = (singleLocFormData, locationClientName, errors) => {

        if (singleLocFormData?.[locationClientName]) {

            if (singleLocFormData[locationClientName].new &&
                singleLocFormData[locationClientName].new.length > 0) {
                validateClientZipcode(singleLocFormData[locationClientName].new,
                    locationClientName, "new", errors)
            }

            if (singleLocFormData[locationClientName].existing &&
                singleLocFormData[locationClientName].existing.length > 0) {
                validateClientZipcode(singleLocFormData[locationClientName].existing,
                    locationClientName, "existing", errors)
            }

        }

    }

    const validateClientZipcode = (clientData, locationClientName, locationZipcode, errors) => {

        const pincodePattern = /^[0-9]{6}$/;
        const invalidPincodes = []

        // Validating client pincodes
        clientData.map((clientInfo, index) => {
            if (clientInfo?.clientPickupPincodes) {
                let clientZipcode = clientInfo.clientPickupPincodes.indexOf(',') > -1 ? clientInfo.clientPickupPincodes.split(",") : clientInfo.clientPickupPincodes.split("\n");

                clientZipcode.map(zipcode => {
                    if (!pincodePattern.test(zipcode.trim())) {
                        invalidPincodes.push(zipcode)
                    }
                })

                if (invalidPincodes.length > 0) {
                    errors.location[locationClientName][locationZipcode][index].clientPickupPincodes.addError("Invalid pincodes -> " + invalidPincodes.join(', '))
                }
            }
        })

    }

    const triggerApiCreateFlow = (formDataSet) => {

        setFormData({})

        const requestData = {}

        // Adding partner details into request body
        if (formDataSet.isExistingPartnerRadio) {
            requestData["partner"] = {
                "id": formDataSet.existingPartnerSelect
            }
        } else {
            requestData["partner"] = {
                "name": formDataSet.partner.partnerName.replace(/\s+/g, ' ').trim(),
                "contactNumber": formDataSet.partner.partnerPhoneNumber,
                "isLoadsharePartner": formDataSet.loadsharePartnerRadio
            }
            requestData["user"] = {
                "name": formDataSet.user.userName.replace(/\s+/g, ' ').trim(),
                "contactNumber": formDataSet.user.userPhoneNumber,
                "email": formDataSet.user.email
            }

        }

        if ("branchUser" in formDataSet && formDataSet.branchUser
            && (Object.keys(formDataSet.branchUser)).length > 0) {

            const branchUser = {}

            if(formDataSet?.branchUser?.userName?.trim()) {
                branchUser['name'] = formDataSet.branchUser.userName.replace(/\s+/g, ' ').trim()
            }

            if(formDataSet?.branchUser?.userPhoneNumber) {
                branchUser['contactNumber'] = formDataSet.branchUser.userPhoneNumber
            }

            if(formDataSet?.branchUser?.email) {
                branchUser['email'] = formDataSet.branchUser.email
            }

            requestData["branchUser"] = branchUser
        }

        // Adding location details into request body
        const locationReqData = {
            "name": formDataSet.location.locationName.replace(/\s+/g, ' ').trim(),
            "clientLocationName": formDataSet.location.clientLocationName.replace(/\s+/g, ' ').trim(),
            "addressText": formDataSet.location.locationAddress.replace(/\s+/g, ' ').trim(),
            "locPincode": formDataSet.location.locationPincode,
            "staffPayCityId": formDataSet.location.locationStaffPayCity,
            "locationOnboardingOpsType": formDataSet.location.locationOpsType,
            "isValmoLocation": formDataSet.location.isValmoLocation
        }

        if (formDataSet.location.locationGstNumber) {
            locationReqData["gstNumber"] = formDataSet.location.locationGstNumber
        }

        if ("locationDeliveryPincode" in formDataSet.location &&
            formDataSet.location["locationDeliveryPincode"] && formDataSet.location.locationOpsType == "LM") {
            locationReqData["deliveryPincodes"] = formatDeliveryPincodeForRequest(formDataSet.location.locationDeliveryPincode)
        }

        if ("locationClient" in formDataSet.location && (["LM", "FM"].includes(formDataSet.location.locationOpsType)) &&
            (formDataSet.location?.locationClient?.new && formDataSet.location.locationClient.new.length > 0 ||
                formDataSet.location?.locationClient?.existing && formDataSet.location.locationClient.existing.length > 0)) {
            locationReqData["customer"] = formatCustomerDetails(formDataSet.location.locationOpsType, formDataSet.location.locationClient, false)
        }

        if ("rvpLocationClient" in formDataSet.location && formDataSet.location.locationOpsType == "LM" &&
            ((formDataSet.location?.rvpLocationClient?.new && formDataSet.location.rvpLocationClient.new.length > 0) ||
                formDataSet.location?.rvpLocationClient?.existing && formDataSet.location.rvpLocationClient.existing.length > 0)) {
            locationReqData["rvpCustomer"] = formatCustomerDetails(formDataSet.location.locationOpsType, formDataSet.location.rvpLocationClient, false)
        }

        requestData["location"] = [locationReqData]

        // Triggering create location api
        locationService.createLocationOnboarding(requestData)
            .then(res => {
                if (res?.status?.code == 202) {
                    notifyApiSuccess("Request has been successfully processed")
                    storeEntityDetails(
                        { ...res?.response?.entityDetails, locationOpsType: formDataSet.location.locationOpsType }
                    )
                    onSubmitSuccess()
                } else {
                    setFormData(formDataSet)
                    notifyApiError(res?.status?.message)
                    console.error("createLocationOnboarding failed", res?.status?.message)
                }

            })
            .catch(error => {
                setFormData(formDataSet)
                notifyApiError(error)
            });

    }

    const triggerApiUpdateFlow = (formDataSet) => {

        formDataSet.location.locationAddress.replace(/\n+/g, ' ');

        const request = {
            "name": formDataSet.location.locationName.replace(/\s+/g, ' ').trim(),
            "address": formDataSet.location.locationAddress.replace(/\s+/g, ' ').trim()
        }

        if (formDataSet?.location?.locationGstNumber) {
            request["gstNumber"] = formDataSet.location.locationGstNumber.trim()
        }

        if (formDataSet.location.locationOpsType == "LM") {

            if ("rvpLocationClient" in formDataSet.location) {
                request['rvpClientLocDetail'] = formatCustomerDetails(formDataSet.location.locationOpsType,
                    formDataSet.location.rvpLocationClient, true)
            }

            if ("locationDeliveryPincode" in formDataSet.location && formDataSet.location.locationDeliveryPincode) {
                request["deliveryPincodes"] = formatDeliveryPincodeForRequest(formDataSet.location.locationDeliveryPincode)
            } else {
                request["deliveryPincodes"] = []
            }

        }

        if (["FM", "LM"].includes(formDataSet.location.locationOpsType) && "locationClient" in formDataSet.location) {
            request['clientLocDetail'] = formatCustomerDetails(formDataSet.location.locationOpsType,
                formDataSet.location.locationClient, true)
        }

        locationService.updateLocationV3(request, locationId)
            .then(res => {
                notifyApiSuccess("Request has been successfully processed")
                navigate("/appv2/settings/dashboard/location")
            })
            .catch(error => {
                console.error("updateLocationOnboarding failed", error)
                setFormData(formDataSet)
            });
    }

    const formatDeliveryPincodeForRequest = (locationDeliveryPincode) => {

        if (locationDeliveryPincode.indexOf(',') > -1) {
            return (locationDeliveryPincode.split(",")).map(element => {
                return parseInt(element.trim());
            })
        } else {
            return (locationDeliveryPincode.split("\n")).map(element => {
                return parseInt(element.trim());
            })
        }
    }

    const formatCustomerDetails = (locationOpsType, clientInfo, updateFlow) => {

        const locClient = []

        if (clientInfo?.new && clientInfo.new.length > 0) {
            for (const { clientName, clientPickupPincodes, isSelfServed } of clientInfo.new) {
                // Splitting the pickup pincodes of a customer location.
                const pickupPincodes = clientPickupPincodes.split(/[,\n]/).map((code) => parseInt(code.trim(), 10));
                // Fetching the already existing client index if present.
                const existingElemIndex = locClient.findIndex((element) => element.id === clientName);
                if (existingElemIndex !== -1) {
                    locClient[existingElemIndex].pickupPincodes.push(...pickupPincodes);
                } else {
                    const locData = {
                        pickupPincodes
                    }

                    if (updateFlow) {
                        locData["clientId"] = clientName
                        locData["clientName"] = customerIdToName[clientName]
                    } else {
                        locData["id"] = clientName
                    }

                    if (["FM", "LM"].includes(locationOpsType) && isSelfServed != null) {
                        locData["isSelfServed"] = isSelfServed
                    }

                    locClient.push(locData);
                }
            }
        }

        if (clientInfo?.existing && clientInfo.existing.length > 0) {
            for (const { clientName, clientPickupPincodes, isSelfServed } of clientInfo.existing) {
                // Splitting the pickup pincodes of a customer location.
                const pickupPincodes = clientPickupPincodes.split(/[,\n]/).map((code) => parseInt(code.trim(), 10));
                // Fetching the already existing client index if present.
                const existingElemIndex = locClient.findIndex((element) => element.id === clientName);
                if (existingElemIndex !== -1) {
                    locClient[existingElemIndex].pickupPincodes.push(...pickupPincodes);
                } else {
                    const locData = {
                        pickupPincodes
                    }

                    if (updateFlow) {
                        locData["clientId"] = clientName
                        locData["clientName"] = customerIdToName[clientName]
                    } else {
                        locData["id"] = clientName
                    }

                    if (["FM", "LM"].includes(locationOpsType) && isSelfServed != null) {
                        locData["isSelfServed"] = isSelfServed
                    }

                    locClient.push(locData);
                }
            }
        }

        return locClient

    }

    const onFormChange = (formEntityData) => {

        if (formData?.existingPartnerSelect != formEntityData?.existingPartnerSelect) {
            setFormData(formEntityData);

            captureEntityData(
                {
                    "partnerId": formEntityData?.existingPartnerSelect,
                    "partnerName": partnerIdToName[formEntityData?.existingPartnerSelect]
                }
            )
        }

        if (!Number.isInteger(Number.parseInt(locationId)) &&
            (formData?.location?.locationName != formEntityData?.location?.locationName)) {

            const lastLocName = formEntityData?.location?.locationName ? formEntityData.location.locationName.split('/').pop() : null;
            const clientLocationName = lastLocName ? lastLocName.slice(-5) : null

            // Create a new object to avoid potential mutations (recommended)
            const updatedFormData = {
                ...formEntityData,
                location: {
                    ...formEntityData.location,
                    clientLocationName,
                },
            };

            setFormData(updatedFormData);
        }


    }

    return (
        <>
            <div className='flex-box loc-onboarding-container' style={{ padding: "20px" }}>
                <div className='flex-1'>
                    {formSchemaValue &&
                        <FormWrapper {...formSchemaValue} validate={validate}
                            onSubmit={onSubmit} widgets={FormWidgets} formData={formData}
                            ArrayFieldTemplate={CustomArrayFieldTemplate}
                            onChange={onFormChange}
                        />
                    }
                </div>
                <div className='flex-1'></div>
            </div>
        </>
    )

}

export default LocationOnboardingForm;
