import { Radio } from "antd";
import RadioGroup from "antd/lib/radio/group";
import { useState } from "react";
import MetabaseReport from "../components/metabase.report";
import ForwardPickupPlanning from "../../metabase-dashboards/dashboard/forward/PickupPlanning";
import FeMonitoring from "../../metabase-dashboards/dashboard/forward/FeMonitoring";

export default function FMDashboard() {

    const subTabs = [
        {
            label: "Pickup Planning",
            reportName: "fm-pickup-planning-dashboard"
        },
        {
            label: "FE Monitoring",
            reportName: "fm-pickup-operations-monitoring-dashboard"
        },
        {
            label: "Hub Monitoring",
            reportName: "fm-hub-operations"
        }
    ];

    const subtabComponentMap = {
        "fm-pickup-planning-dashboard": {
            componentName: <ForwardPickupPlanning />
        },
        "fm-pickup-operations-monitoring-dashboard": {
            componentName: <FeMonitoring />
        }
    }

    const loadOnUserDemandComplete = {
        "fm-hub-operations": {
            titleCardName: "FM HUB Operations"
        }
    }

    const [selectedTab, SetSelectedTab] = useState("fm-pickup-planning-dashboard");

    const onTabChange = key => {
        SetSelectedTab(key);
    }

    console.log("subtabComponentMap?.selectedTab?.componentName", subtabComponentMap?.selectedTab?.componentName)

    return (
        <div className="flex-column flex-gap-m align-items-stretch" style={{ marginBottom: 15 }}>
            <RadioGroup buttonStyle="solid" onChange={event => onTabChange(event?.target?.value)} value={selectedTab} >
                {
                    subTabs.map(t => <Radio.Button className="report-preview-radio" value={t.reportName}> {t.label} </Radio.Button>)
                }
            </RadioGroup>

            {subtabComponentMap[selectedTab]?.componentName ?
                subtabComponentMap[selectedTab].componentName
                :
                <MetabaseReport reportName={selectedTab} hideBackButton={true}
                    autoRefresh={true} autoRefreshTimetout={300}
                    loadOnUserDemandObj={
                        (loadOnUserDemandComplete[selectedTab] ?
                            { loadOnUserDemand: true, titleCardName: loadOnUserDemandComplete[selectedTab].titleCardName } : {})
                    }
                />
            }

        </div>
    )
}