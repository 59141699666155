import React from "react";
import MetabaseWidget from "../components/metabase.widget";


const LastMileExceptions = () => {

    return (
        <div className="flex-column flex-gap-xl" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">LM | Exceptions</div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">For Forward Flow</div>

                <div className="flex-box flex-gap-m justify-content-center">

                    <MetabaseWidget text={"LM - Forward - Unresolved Misroutes Received"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1066, reportName: "lm-forward-unresolved-misroutes-received",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                    <MetabaseWidget text={"LM - Forward - Unresolved Shortages"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1067, reportName: "lm-forward-unresolved-shortages",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                    <MetabaseWidget text={"LM - Forward - Unresolved Overages"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1068, reportName: "lm-forward-unresolved-overages",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>
            </div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">For RTO Flow</div>

                <div className="flex-box flex-gap-m justify-content-center">

                    <MetabaseWidget text={"LM - RTO - Unresolved Misroutes Received"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1069, reportName: "lm-rto-unresolved-misroutes-received",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                    <MetabaseWidget text={"LM - RTO - Unresolved Shortages"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1070, reportName: "lm-rto-unresolved-shortages",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                    <MetabaseWidget text={"LM - RTO - Unresolved Overages"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1071, reportName: "lm-rto-unresolved-overages",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>
            </div>


            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">Data Dump</div>

                <MetabaseWidget text={"LM | Exception Data Dump"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1117, reportName: "lm-exception-data-dump",
                        lockedParams: ["location_id"], height: "400px"
                    }} />
            </div>


        </div>
    )

}

export default LastMileExceptions