import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import MetabaseQuestion from "../../common/metabase-question/metabase.question";
import MetabaseWidget from "../../metabase-dashboards/components/metabase.widget";

export default function ExpectedComponent() {

    const location = useLocation();
    const [reportReferenceJSON, SetReportReferenceJSON] = useState(undefined);

    useEffect(_ => {
        var __reportReferenceJSON = {
            questionNo: 2250,
            reportName: "expected-shipments-enroute",
            lockedParams: ["location_id"],
            customParams: {
                status: "IN_TRANSIT,VEHICLE_ARRIVED,MANIFEST_RECEIVED",
                flow_type: "FORWARD"
            },
            height: "400px"
        };
        if (location.pathname.indexOf("rto") > -1) {
            __reportReferenceJSON = {
                ...__reportReferenceJSON,
                reportName: "rto-expected-shipments-enroute",
                customParams: {
                    status: "RTO_INTRANSIT,RTO_VEHICLE_ARRIVED,RTO_MANI_RECEIVED",
                    flow_type: "RTO"
                }
            }
        }
        SetReportReferenceJSON(__reportReferenceJSON);
    }, [location.pathname])


    return (
        reportReferenceJSON === undefined ?
            <Spin spinning={true}></Spin> :
            <div>
                <MetabaseWidget text={"Expected Shipments - Enroute"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{ ...reportReferenceJSON }} />
            </div>
    );
}