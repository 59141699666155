import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const ScDailyActions = () => {

    return (
        <>
            <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

                <div className="font-size-l-3 grey-text">SC | Daily Action File - Forward View</div>

                <div className="flex-column flex-gap-xl">
                    <div
                        className="metabase-subheader-container text-align-center font-size-l-1 border-radius-m">
                        Inbound or Arrived Trips
                    </div>

                    <MetabaseWidget text={"SC - Inbound/ Forward En route"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1399, reportName: "sc-inbound-forward-en-route",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                    <MetabaseWidget text={"SC - Inbound/ Forward Arrived"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1401, reportName: "sc-inbound-forward-arrived",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                    <MetabaseWidget text={"SC - Inbound/ Forward Arrived - Past Incomplete"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1435, reportName: "sc-inbound-forward-arrived-past-incomplete",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>

                <div className="flex-column flex-gap-xl">
                    <div
                        className="metabase-subheader-container text-align-center font-size-l-1 border-radius-m"
                    >
                        Inbound or Arrived Manifests
                    </div>

                    <MetabaseWidget text={"SC - Inbound/ Forward Manifest Received"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1403, reportName: "sc-inbound-forward-manifest-received",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                    <MetabaseWidget text={"SC - Inbound/ Forward Manifest Received - Past Incomplete"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1436, reportName: "sc-inbound-forward-manifest-received-past-incomplete",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                </div>

                <div className="flex-column flex-gap-xl">
                    <div
                        className="metabase-subheader-container text-align-center font-size-l-1 border-radius-m"
                    >
                        Sort Center Bagging Pendency View
                    </div>

                    <MetabaseWidget text={"Lanewise - Shipment Pendency in Day Buckets"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1438, reportName: "lanewise-shipment-pendency-in-day-buckets",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                    <MetabaseWidget text={"Lanewise - Shipment Pendency"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1437, reportName: "lanewise-shipment-pendency",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                    <MetabaseWidget text={"Lanewise - Manifest Created not Connected"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1439, reportName: "lanewise-manifest-created-not-connected",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                    <MetabaseWidget text={"Lanewise - Cross dock Manifest Created not Connected"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 2862, reportName: "lanewise-cross-dock-manifest-created-not-connected",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>

                <div className="flex-column flex-gap-xl">
                    <div
                        className="metabase-subheader-container text-align-center font-size-l-1 border-radius-m"
                    >
                        Outbound Forward Trip Dispatched
                    </div>

                    <MetabaseWidget text={"Outbound/ Forward Trips Dispatched"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1443, reportName: "outbound-forward-trips-dispatched",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>

                <div className="flex-column flex-gap-xl">
                    <div
                        className="metabase-subheader-container text-align-center font-size-l-1 border-radius-m"
                    >
                        Data Dump
                    </div>

                    <MetabaseWidget text={"Shipment Pendency - Data Dump"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1449, reportName: "shipment-pendency-data-dump",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                    <div className="flex-box flex-gap-m justify-content-center">
                        <MetabaseWidget text={"SC - Inbound/ Forward En route - Data Dump"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 1444, reportName: "sc-inbound-forward-en-route-data-dump",
                                lockedParams: ["location_id"], height: "300px"
                            }} />

                        <MetabaseWidget text={"SC - Inbound/ Forward Arrived - Data Dump"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 1445, reportName: "sc-inbound-forward-arrived-data-dump",
                                lockedParams: ["location_id"], height: "300px"
                            }} />

                        <MetabaseWidget text={"SC - Inbound/ Forward Arrived - Past Incomplete - Data Dump"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 1446, reportName: "sc-inbound-forward-arrived-past-incomplete-data-dump",
                                lockedParams: ["location_id"], height: "300px"
                            }} />
                    </div>

                    <div className="flex-box flex-gap-m justify-content-center">
                        <MetabaseWidget text={"SC - Inbound/ Forward Manifest Received - Data Dump"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 1452, reportName: "sc-inbound-forward-manifest-received-data-dump",
                                lockedParams: ["location_id"], height: "300px"
                            }} />

                        <MetabaseWidget text={"SC - Inbound/ Forward Manifest Received - Past Incomplete - Data Dump"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 1450, reportName: "sc-inbound-forward-manifest-received-past-incomplete-data-dump",
                                lockedParams: ["location_id"], height: "300px"
                            }} />

                        <MetabaseWidget text={"Lanewise - Shipment Pendency - Data Dump"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 1451, reportName: "lanewise-shipment-pendency-data-dump",
                                lockedParams: ["location_id"], height: "300px"
                            }} />
                    </div>

                    <div className="flex-box flex-gap-m justify-content-center">
                        <MetabaseWidget text={"Lanewise - Manifest Created not Connected - Data Dump"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 1448, reportName: "lanewise-manifest-created-not-connected-data-dump",
                                lockedParams: ["location_id"], height: "300px"
                            }} />

                        <MetabaseWidget text={"Lanewise - Cross dock Manifest Created not Connected -Data Dump"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 2863, reportName: "lanewise-cross-dock-manifest-created-not-connected-data-dump",
                                lockedParams: ["location_id"], height: "300px"
                            }} />


                        <MetabaseWidget text={"Outbound/ Forward Trips Dispatched - Data Dump"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 1447, reportName: "outbound-forward-trips-dispatched-data-dump",
                                lockedParams: ["location_id"], height: "300px"
                            }} />
                    </div>


                </div>

            </div>
        </>
    )

}

export default ScDailyActions;