import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Spin,
} from "antd";
import { connect } from "react-redux";
import RTODRSSparkLine from "./components/rto-drs-spark-line";
import RTODRSTable from "./components/rto-drs-table";
import { getDrsListData, getSparkLineDataFunction } from "./action";
import { FlowTypes } from "../../../common/utils/constants";

const RTODRS = ({
  loading,
  getDrsListFunction,
  getSparkLineDataFunction,
  dataCardList,
  PaginatedDrs,
  newDrsData,
  drsData,
  history,
  config
}) => {
  const [filterObj, setFilterObj] = useState({
    filters: {
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      isRtsDrs: false,
      drsCodes: [],
      drsStatuses: ["PENDING", "IN_TRANSIT", "HANDED_OVER", "COMPLETED", "DRAFT"]
    },
    pageNo: 1,
    flowTypes: FlowTypes.RTO
  });

  const applyPagination = (filters, isReset = false, isSearched = false) => {
    var filter = {
      ...filterObj.filters,
      ...filters
    }
    if (isReset) {
      filter = {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
        isRtsDrs: false,
        drsCodes: [],
        drsStatuses: ["PENDING", "IN_TRANSIT", "HANDED_OVER", "COMPLETED", "DRAFT"]
      }
    }
    setFilterObj({
      filters: filter,
      pageNo: 1,
      flowTypes: FlowTypes.RTO
    })
    if (filterObj.flowType === FlowTypes.RTO) {
      filter["flowTypes"] = [FlowTypes.RTO];
    } else {
      filter["flowTypes"] = [FlowTypes.RTO, FlowTypes.RTS];
    }

    getDrsListFunction({ filters: filter }, isReset, { showLoader: false });
  }

  const getPaginatedData = (pageNo) => {
    applyPagination({ pageNo: pageNo }, false)
    setFilterObj({
      ...filterObj,
      pageNo
    });
  }
  useEffect(() => {
    applyPagination({ pageNo: 1 }, true)
    getSparkLineDataFunction();
  }, []);

  return (
    <div className="rto-drs">
      <Spin spinning={loading}>
        <PageHeader onBack={false} ghost style={{ padding: 0, paddingBottom: "24px" }}>
          <RTODRSSparkLine data={dataCardList}
            createRTODRS={_ => history.push(`/appv2/rto/form/drs`)} />
        </PageHeader>
        <RTODRSTable
          data={drsData}
          getPaginatedData={getPaginatedData}
          pageNo={filterObj.pageNo} newDrsData={newDrsData}
          PaginatedDrs={PaginatedDrs} applyPagination={applyPagination}
          editRTODRS={(id, rtoDrsCode) => history.push(true ? `/appv2/rto/form/drs/${rtoDrsCode}` : `/appv2/rto/form/drs/${id}`)}
        />
      </Spin>
    </div>
  )
};

const mapStateToProps = (state) => ({
  config: state.app.configurations,
  loading: state.rtoDrs.loading,
  drsList: state.rtoDrs.drsList,
  paginationData: state.rtoDrs.paginationData,
  dataCardList: state.rtoDrs.dataCardList,
  PaginatedDrs: state.rtoDrs.PaginatedDrs,
  newDrsData: state.rtoDrs.newDrsData,
});

const mapDispatchToProps = dispatch => ({
  getDrsListFunction: (payload, isReset, param) => dispatch(getDrsListData(payload, isReset, param)),
  getSparkLineDataFunction: () => dispatch(getSparkLineDataFunction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RTODRS);
