import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const ScVisibilityForward = () => {

    return (
        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">Sort Center - Visibility - Forward</div>

            <div className="flex-column flex-gap-xl">
                <div
                    className="metabase-subheader-container font-size-l-1 border-radius-m">
                    Today's Summary
                </div>


                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC - Forward - Shipments Arriving"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1201, reportName: "sc-forward-shipments-arriving",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC -  Forward - Bags Arriving"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1202, reportName: "sc-forward-bags-arriving",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC -  Forward - In-scans Today"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1203, reportName: "sc-forward-in-scans-today",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC -  Forward - Shipments In Scanned and not Bagged"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1204, reportName: "sc-forward-shipments-in-scanned-and-not-bagged",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC -  Forward - Shipments In Bagged and not Shipped"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1205, reportName: "sc-forward-shipments-in-bagged-and-not-shipped",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>

            </div>

            <div className="flex-column flex-gap-xl">
                <div
                    className="metabase-subheader-container font-size-l-1 border-radius-m">
                    Time Buckets
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC - Forward - Received to Bagged - Time Taken Bucketed"}
                        className={"flex-3 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1212, reportName: "sc-forward-received-to-bagged-time-taken-bucketed",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC - Forward - Received to Bagged - Time Taken Average"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1214, reportName: "sc-forward-received-to-bagged-time-taken-average",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC - Forward - Bagged to Shipped - Time Taken Bucketed"}
                        className={"flex-3 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1217, reportName: "sc-forward-bagged-to-shipped-time-taken-bucketed",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC - Forward - Bagged to Shipped - Time Taken Average"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1221, reportName: "sc-forward-bagged-to-shipped-time-taken-average",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>

            </div>

            <div className="flex-column flex-gap-xl">
                <div
                    className="metabase-subheader-container font-size-l-1 border-radius-m">
                    Data Dump
                </div>

                <MetabaseWidget text={"Action Pendency - Data Dump - Forward"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1261, reportName: "action-pendency-data-dump-forward",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

                <MetabaseWidget text={"Forward - Lane Wise Details - Sort Center"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1309, reportName: "forward-lane-wise-details-sort-center",
                        lockedParams: ["location_id"], height: "400px"
                    }} />
            </div>

        </div>
    )

}

export default ScVisibilityForward;