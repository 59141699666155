import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const ScVisibilityExceptions = () => {

    return (
        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">Sort Center - Visibility - Exceptions</div>

            <div className="flex-column flex-gap-xl">
                <div
                    className="metabase-subheader-container font-size-l-1 border-radius-m">
                    For Forward Flow
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC - Forward - Unresolved Misroutes Received"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1298, reportName: "sc-forward-unresolved-misroutes-received",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC - Forward - Unresolved Shortages"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1300, reportName: "sc-forward-unresolved-shortages",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC - Forward - Unresolved Overages"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1299, reportName: "sc-forward-unresolved-overages",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>
            </div>

            <div className="flex-column flex-gap-xl">
                <div
                    className="metabase-subheader-container font-size-l-1 border-radius-m">
                    For RTO Flow
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC - RTO - Unresolved Misroutes Received"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1302, reportName: "sc-rto-unresolved-misroutes-received",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC - RTO - Unresolved Shortages"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1301, reportName: "sc-rto-unresolved-shortages",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC - RTO - Unresolved Overages"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1303, reportName: "sc-rto-unresolved-overages",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>
            </div>

            <div className="flex-column flex-gap-xl">
                <div
                    className="metabase-subheader-container font-size-l-1 border-radius-m">
                    Data Dump
                </div>

                <MetabaseWidget text={"SC - Exception Data Dump"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1304, reportName: "sc-exception-data-dump",
                        lockedParams: ["location_id"], height: "400px"
                    }} />
            </div>

        </div>
    )

}

export default ScVisibilityExceptions;