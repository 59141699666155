import { Button, Card, Icon, Modal } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Constants, DownloadTags } from '../../../common/utils/constants';
import { checkReachAuditEnabled, notifyApiError, reportsDownload } from '../../../common/utils/utils';
import { table as Table } from "../../../components/ui/table/Table";
import AuditService from '../../../services/audit/audit.service';
import { fetchAudits, getAuditStatus } from './action';

const auditService = new AuditService;
const enabledRearchAudit = checkReachAuditEnabled()

class Audit extends Component {

    constructor(props) {

        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        super(props)
        let filterPayload = {
            filters: {
                pageNo: 0,
                pageSize: 10,
                totalCount: 0,
                dateRange: {
                    from: sevenDaysAgo.getTime(),
                    to: Date.now()
                }
            }
        }
        if (enabledRearchAudit) {
            filterPayload = {
                pageNo: 0,
                pageSize: 10,
            }
        }
        this.state = {
            pageNo: 1,
            columns: [
                {
                    title: 'Audit SL',
                    dataIndex: 'id',
                    key: 1
                },
                {
                    title: 'Audit ID',
                    dataIndex: 'auditId',
                    key: 2
                },
                {
                    title: 'Date',
                    dataIndex: 'createdAt',
                    key: 3,
                    render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
                },
                {
                    title: 'User',
                    dataIndex: 'userName',
                    key: 4
                },
                {
                    title: 'Expected to be Scanned',
                    dataIndex: `${enabledRearchAudit ? 'inlocationCount' : 'inLocationCount'}`,
                    key: 5,
                    render: (data, row) => (data + row.missingCount)
                },
                {
                    title: 'In Location',
                    dataIndex: `${enabledRearchAudit ? 'inlocationCount' : 'inLocationCount'}`,
                    key: 6
                },
                {
                    title: 'Overage',
                    dataIndex: 'unexpectedCount',
                    key: 7
                },
                {
                    title: 'Scanned',
                    dataIndex: 'scannedCount',
                    key: 8,
                    render: data => data || 'NA'
                },
                {
                    title: 'Missing Scanned',
                    dataIndex: 'missingCount',
                    key: 9
                },
                {
                    title: 'Action',
                    dataIndex: 'missingCount',
                    key: 10,
                    render: (__, row) => (
                        <div>
                            {
                                !row.isFinished &&
                                <Icon type="edit"
                                    onClick={_ => this.props.history.push(`/appv2/inventory/form/audit/${row.id}`)} />
                            }
                            {
                                row.filepath && row.isFinished &&
                                <Button icon="download" type="primary" className="lsn-btn-primary"
                                    onClick={e => reportsDownload(row.filepath, DownloadTags.s3)}>
                                    Download
                                </Button>
                            }
                        </div>
                    ),
                }
            ],
            filterPayload: filterPayload,
            disableAction: false,
            createAuditAction: false
        }
    }

    componentDidMount() {
        this.applyPagination({ pageNo: this.state.pageNo }, true)

    }

    getPaginatedData = (pageNo, paginatedData) => {
        this.setState({
            pageNo
        }, () => this.applyPagination({ pageNo }));
    }

    applyPagination = (filters, isReset = false) => {
        if (isReset) {
            this.setState({
                pageNo: 1
            })
        }
        if (enabledRearchAudit) {
            this.props.fetchAudits({ ...this.state.filterPayload.filters, ...filters }, isReset);
        } else {
            this.props.fetchAudits({ filters: { ...this.state.filterPayload.filters, ...filters } }, isReset);
        }

    }

    confirmAuditStart = e => {
        const that = this
        this.setState({
            createAuditAction: true
        }, () => Modal.confirm({
            title: 'CONFIRM START AUDIT',
            content: <h4 style={{ fontWeight: 400 }}>
                You are expected to scan all the shipments at your location. No user in your branch will be able to receive or dispatch shipments from your location when the audit is in-progress.
                <br /><br />
                Are you sure you want to start the audit?
            </h4>,
            width: '40%',
            onOk() {
                that.createAudit()
            },
            okText: 'Yes, Start Audit',
            onCancel() {
                that.setState({
                    createAuditAction: false
                })
            },
        }));
    }

    createAudit = () => {
        const params = {
            'auditInfoBO': { 'locationId': Constants.userLocationId },
            'action': 'CREATE'
        };
        if (enabledRearchAudit) {
            auditService.createRearchAudit(params)
                .then((data) => {
                    this.setState({
                        createAuditAction: false
                    })
                    if (data && data.id) {
                        this.props.getAuditStatus({ locationId: Constants.LOGGED_PARTNER_LOCATION_ID });
                        this.applyPagination({ pageNo: 1 }, true)
                        this.props.history.push(`/appv2/inventory/form/audit/${data.id}`)
                    } else {
                        notifyApiError(data, 'Error')
                    }
                })
                .catch(err => {
                    this.setState({
                        createAuditAction: false
                    })
                    notifyApiError('Something went wrong. Please try again')
                })
        } else {
            auditService.createAudit(params)
                .then(({ data }) => {
                    this.setState({
                        createAuditAction: false
                    })
                    if (data && data?.response && data.response?.sync) {
                        this.props.getAuditStatus({ locationId: Constants.LOGGED_PARTNER_LOCATION_ID });
                        this.applyPagination({ pageNo: 1 }, true)
                        this.props.history.push(`/appv2/inventory/form/audit/${data.response.auditInfos[0]['id']}`)
                    } else {
                        if (data && data?.response && data.response?.auditInfos) {
                            this.props.getAuditStatus({ locationId: Constants.LOGGED_PARTNER_LOCATION_ID });
                            this.applyPagination({ pageNo: 1 }, true)
                        }
                        notifyApiError(data.status.message, 'Error')
                    }
                })
                .catch(err => {
                    this.setState({
                        createAuditAction: false
                    })
                    notifyApiError('Something went wrong. Please try again')
                })
        }
    }

    render() {
        const { columns, pageNo, createAuditAction } = this.state;
        const { auditData, PaginatedAudit, auditStatus } = this.props
        const actionBtn = [
            <Button disabled={(createAuditAction || auditStatus?.isAuditOn)} block={true} type="primary"
                onClick={this.confirmAuditStart} className="lsn-btn-primary" >
                Start Audit
            </Button>
        ]
        return (
            <div className="audit">
                <Card className="audit" title={<h2>Audit List</h2>} extra={actionBtn}>
                    <Table bordered={true} loading={auditData?.loading}
                        pagination={{
                            onChange: this.getPaginatedData,
                            total: auditData?.totalCount,
                            position: 'bottom',
                            current: pageNo,
                            pageSize: auditData?.pageSize
                        }}
                        rowKey={row => row.id} columns={columns}
                        dataSource={PaginatedAudit ? PaginatedAudit[pageNo] : []} />
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    auditData: state.audit,
    auditStatus: state.audit?.auditStatus,
    PaginatedAudit: state.audit?.PaginatedAudits,
});
const mapDispatchToProps = dispatch => ({
    fetchAudits: (data, isReset) => dispatch(fetchAudits(data, isReset)),
    getAuditStatus: (param) => dispatch(getAuditStatus(param)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Audit);