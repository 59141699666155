import { Avatar, Button, Dropdown, Form, Icon, Input, Menu, Modal, Select, Spin } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getPermissions, getConfigurations } from '../../../app/appAction';
import { ReactComponent as Logo } from "../../../assets/img/logo_log10.svg";
import { Constants, SELF, USER_LEVEL, USER_LOGGEDIN_INFO } from "../../../common/utils/constants";
import { compareObjectsByKeys, filterSelectOptions, notifyApiError, reloadApp } from "../../../common/utils/utils";
import { AuthenticationService } from "../../../services/authentication.service";
import { Permissions } from "../../../services/permissions.service";
import TrackingPopupModal from "../../shared-components/tracking-popup-modal";
import * as trackingActions from "../../tracking/action";
import { openNotification } from "../../ui/notification/Notification";
import * as actions from "./action";
import packageInfo from "../../../../package.json";
import moment from "moment-timezone";

const { Option } = Select;

let selectedPartnerName = "", selectedPartnerLocationName = "";

class Header extends Component {

  state = {
    configFlag: false,
    selectedPartner: null,
    selectedPartnerLocation: null,
    searchedWaybill: "",
    showTrackingPopup: false,
    showHubModal: false,
    confirmModalVisible: false,
    selectedConfirmModal: '',
    confirmLoader: false,
    hubLoader: false,
    configFetched: false,
    permissionFetched: false,
    showAdminOptions: false,
  };

  componentDidMount() {
    Constants.HEADER_CLASSNAME = this.constructor.name;
    this.props.getLoggedInUserInfo();
    this.props.storeGetDataLocalStorage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login !== this.props.login) {
      Permissions.storeLoginData({
        token: this.props.login.token,
        currentLocationName: this.props.login.user.location.name,
        currentPartnerName: this.props.login.user.partner.name,
        currentPartnerLocationOpsType: this.props.login.user.location.locationOpsType
      });
      openNotification({
        message: "Information",
        description: "Latest data has been set",
        className: "warning"
      });
    }

    if (JSON.stringify(prevProps.userInfo) !== JSON.stringify(this.props.userInfo)) {
      if ([USER_LEVEL.ADMIN, USER_LEVEL.LSN, USER_LEVEL.PARTNER_HUB, USER_LEVEL.HEAD_OFFICE].indexOf(this.props.loggedInUser?.userLevel) > -1) {
        if (this.props.loggedInUser?.location?.entityType !== USER_LOGGEDIN_INFO.CUSTOMER) {
          this.props.getPartnerDetails(this.props.userInfo.loggedPartnerId);
        }
        this.setState({
          showAdminOptions: true
        })
      }
    }

    if (prevProps.partners !== this.props.partners && !compareObjectsByKeys(prevProps.partners, this.props.partners)) {
      this.partnerHandleChange(
        this.props?.userInfo?.currentPartnerId,
        undefined,
        true
      );
    }

    if (prevProps.partnerLocations !== this.props.partnerLocations) {
      this.partnerLocationhandleChange(
        this.props?.userInfo?.currentPartnerLocationId,
        undefined,
        true
      );
    }

    if (this.props.refreshApp) {
      reloadApp(this.props.login);
    }

    if (this.props.waybillDetails !== prevProps.waybillDetails && this.props.source === Constants.HEADER_CLASSNAME) {
      if (this.props.waybillDetails?.tracking?.length > 0) {
        this.showPopup();
      } else {
        notifyApiError(
          `No Tracking details found for ${this.state.searchedWaybill}`,
          "Data not found"
        );
        this.handleCancel()
      }
    }

    if (this.props.permissions && this.props.permissions !== prevProps.permissions) {
      this.setState({
        permissionFetched: true
      });
    }

    if (this.props.configurations !== prevProps.configurations && this.state.configFlag) {
      this.setState({
        configFlag: false,
        configFetched: true
      });
    }

    if (this.state.configFetched && this.state.permissionFetched) {
      this.setState({
        confirmLoader: false,
        confirmModalVisible: false
      })
      reloadApp();
    }

    if (this.props.isWaybillModalOpen !== prevProps.isWaybillModalOpen || this.props.waybillInput !== prevProps.waybillInput) {
      this.setState({
        searchedWaybill: this.props.waybillInput
      }, this.onSearchWaybill)
    }
  }

  getPartnerNameByID = partnerId => {
    const selectedPartner = this.props.partners.find(partner => {
      return partner.linkPartnerBO.id === partnerId;
    });
    if (selectedPartner) {
      return selectedPartner?.linkPartnerBO?.name;
    }
    return SELF;
  };

  getLocationNameById = locationId => {
    const selectedLocation = this.props?.partnerLocations?.find(location => {
      return location.id === locationId;
    });
    if (selectedLocation) {
      return selectedLocation.name;
    }
    return "Select Location";
  };

  getLocationOpsTypeById = locationId => {
    const selectedLocation = this.props?.partnerLocations?.find(location => {
      return location.id == locationId;
    });
    if (selectedLocation) {
      return selectedLocation.locationOpsType;
    }
    return null;
  };

  partnerHandleChange = (partnerId, event, auto = false) => {
    let partnerToDisplay = partnerId;
    if (auto) {
      partnerToDisplay = this.getPartnerNameByID(partnerId);
    }

    selectedPartnerName = partnerToDisplay;
    this.setState({
      selectedPartner: partnerToDisplay,
      selectedPartnerLocation: "Select Location"
    });

    this.fetchPartnerLocations(event?.key || partnerId);
  };

  partnerLocationhandleChange = (locationId, event, auto = false) => {

    this.setState({
      hubLoader: !auto
    }, () => {
      localStorage.removeItem("managePickupFilterResponse");
      if (!isNaN(this.state.selectedPartner)) {
        this.props.updateLocalItemInRedux({
          key: "currentPartnerId",
          value: this.state.selectedPartner
        });
      }
      this.props.updateLocalItemInRedux({
        key: "currentPartnerLocationId",
        value: locationId
      });

      let locOpsType = this.getLocationOpsTypeById(locationId)

      if (locOpsType) {
        this.props.updateLocalItemInRedux({
          key: "currentPartnerLocationOpsType",
          value: locOpsType
        });
      }

      let locationToDisplay = locationId;

      if (auto) {
        locationToDisplay = this.getLocationNameById(locationId);
      }
      selectedPartnerLocationName = locationToDisplay
      this.setState({
        selectedPartnerLocation: locationToDisplay,
        refreshLocalData: true,
      }, () => this.props.appLogin({ locationId: locationId, auto }));
    })
  };

  fetchPartnerLocations = (partnerId = this.state.selectedPartner) => {
    if (isNaN(partnerId)) {
      partnerId = this.props.userInfo.currentPartnerId;
    }
    const req = {
      filters: {
        entityTypes: [this.props.loggedInUser?.location?.entityType],
        entityIds:
          this.props.loggedInUser?.location?.entityTypes ===
            USER_LOGGEDIN_INFO.CUSTOMER
            ? [this.props.loggedInUser?.location?.entityId]
            : [partnerId],
        isConsiderCreatorId: false,
        // roles: [],
        "fetchObjects": [],
        status: true
      }
    };
    this.props.fetchPartnerLocatinDetails(req, partnerId);
  };

  logout = () => {
    this.props.userLogout()
      .then((res) => {
        AuthenticationService.logoutUser()
      })
  };

  onWaybillChange = event => {
    this.setState({
      searchedWaybill: event.target.value
    });
  };

  onSearchWaybill = () => {
    if (!this.state.searchedWaybill.trim()) {
      return;
    }
    this.props.getWaybillTrackingDetails({
      waybillNo: this.state.searchedWaybill,
      source: Constants.HEADER_CLASSNAME
    });
  };

  showPopup = () => {
    this.setState({
      showTrackingPopup: true
    });
  };

  handleCancel = e => {
    this.props.toggleWaybillModal('', false)
    this.setState({
      showTrackingPopup: false,
      showHubModal: false,
      confirmModalVisible: false,
      searchedWaybill: ''
    });
  };

  onKeyPress = e => {
    if (e.key === "Enter") {
      this.onSearchWaybill();
    }
  };

  getAvatar = name => {
    let avatar = '';
    const names = name.split(' ');
    names.forEach(item => {
      if (item)
        avatar += item[0].toUpperCase();
    })

    return avatar
  }

  loadConfig = () => {
    this.setState({
      configFlag: true,
      confirmModalVisible: true,
      selectedConfirmModal: 'Config & Permissions'
    })
  }

  confirmed = () => {
    this.setState({
      confirmLoader: true
    }, () => {
      this.props.getPermissions();
      this.props.getConfigurations();
    })
  }

  render() {

    const appVersion = packageInfo.version.substring(packageInfo.version.indexOf("-") + 1, packageInfo.version.lastIndexOf("."));
    const currentERPVersion = (this.props.configurations && this.props.configurations?.currentERPVersion) || 0;
    const prevERPVersion = (this.props.configurations && this.props.configurations?.previousERPVersion) || 0;
    var erpStatus = 1;
    if (currentERPVersion != 0 && appVersion < currentERPVersion) {
      erpStatus = 0;
    }
    if (prevERPVersion != 0 && appVersion < prevERPVersion) {
      erpStatus = -1;
    }
    const profileMenu = (
      <Menu>
        <Menu.Item key="4">
          <div className="flex-column flex-gap-l justify-content-center align-items-center">
            <Avatar size={50} className="primary-bg">
              {this.getAvatar(this.props.loggedInUser?.name ? this.props.loggedInUser?.name : ' ')}
            </Avatar>
            <div className="flex-column flex-gap-m justify-content-center align-items-center font-size-m-3" >
              <div> {this.props.loggedInUser?.name} </div>
              <div>
                {
                  Constants.sessionUser?.location?.entityType == 'CUSTOMER' ?
                    <> {this.props?.loggedInUser?.location?.entity?.custObject?.name} </> :
                    <> {this.props?.loggedInUser?.partner?.name} ({this.props?.loggedInUser?.location?.entityType}) </>
                }
              </div>
            </div>
          </div>
        </Menu.Item>
        <Menu.Divider />
        {
          [USER_LEVEL.ADMIN, USER_LEVEL.LSN, USER_LEVEL.PARTNER_HUB, USER_LEVEL.HEAD_OFFICE].indexOf(this.props.loggedInUser?.userLevel) > -1 &&
          <Menu.Item key="0" onClick={() => { this.setState({ showHubModal: true }) }}>
            <Icon type="swap" className="icon" /> Switch Hub ({this.state.selectedPartner || selectedPartnerName} - {this.state.selectedPartnerLocation || selectedPartnerLocationName})
          </Menu.Item>
        }
        <Menu.Item key="1" onClick={this.loadConfig}>
          <Icon type="reload" className="icon" /> Reload System (v{appVersion})
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2" onClick={this.logout}>
          <Icon type="poweroff" className="icon" /> Logout
        </Menu.Item>
      </Menu>
    )


    return (
      <div className="flex-column align-items-stretch">
        {
          erpStatus == 0 ?
            <div className="font-size-m-3 warning-tag flex-box align-items-center justify-content-center flex-gap-m spacer-vxs"
              style={{ borderWidth: 1, borderStyle: "solid" }}>
              <Icon type="info-circle" style={{ fontSize: 22 }} />
              New version of application has been released. Please Refresh page to get the latest application.
            </div> :
            erpStatus == -1 ?
              <div className="font-size-m-3 error-tag flex-box align-items-center justify-content-center flex-gap-m spacer-vxs"
                style={{ borderWidth: 1, borderStyle: "solid" }}>
                <Icon type="warning" style={{ fontSize: 22 }} />
                You are on an older version. Please Refresh page to get latest application and avoid issues.
              </div> :
              <></>
        }
        <div className={"Header spacer-s flex-box justify-content-space-between align-items-center" + (this.props.isMobile ? " mweb-wrapper" : "")}>
          <Logo id="header-logo" className="cursor-pointer" height={60} onClick={_ => this.props.history.push("/appv2/tracking/dashboard/tracking")} />
          <div className="flex-1 flex-box flex-gap-l justify-content-flex-end align-items-center spacer-hm">

            {
              this.props.isMobile ?
                <Icon type="desktop" style={{ color: "white", fontSize: 22 }} onClick={_ => this.props.history.push("/appv2/tracking/dashboard/tracking")} /> :
                <Icon type="mobile" style={{ color: "white", fontSize: 22 }} onClick={_ => this.props.history.push("/appv2/mweb")} />
            }

            {
              !(["CUSTOMER_ADMIN", "CUSTOMER_SUPER_ADMIN", "CUSTOMER_USER"].indexOf(this.props.loggedInUser?.userLevel) > -1) &&
              <div className="waybill-track flex-box">
                <Input type="text" placeholder="Search Waybill" disabled={this.props.fetchingConfig}
                  onChange={this.onWaybillChange} value={this.state.searchedWaybill}
                  onKeyPress={this.onKeyPress} size="default" />
                <Button type="button" label="Search" onClick={this.onSearchWaybill} style={{ borderRadius: "0px 6px 6px 0px", marginLeft: -5 }}>
                  Search
                </Button>
              </div>
            }

            <Dropdown overlay={profileMenu} trigger={['click']}>
              <div className="flex-box flex-gap-s align-items-center white-color font-size-l-1 cursor-pointer">
                {
                  this.props.isMobile ?
                    <span className="header-icon-wrapper">
                      <Icon type="user" />
                    </span> :
                    <>
                      <div> {this.props.loggedInUser?.name} </div>
                      <Icon type="down" style={{ fontSize: 18, fontWeight: "bold" }} />
                    </>
                }
              </div>
            </Dropdown>
          </div>
          <Modal onCancel={this.handleCancel} visible={this.state.showHubModal}
            footer={false} title="Switch Hub"
            centered={true} destroyOnClose={true}
            wrapClassName={'hub-modal'} maskClosable={false} >
            <Spin spinning={this.state.hubLoader} indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}>
              <Form className="login-form">
                {
                  [268, 269].indexOf(Constants.PARTNER_ID) > -1 &&
                  <Form.Item label="Partner">
                    <Select loading={this.props.partnerLoading} notFoundContent="Partner List not present"
                      filterOption={filterSelectOptions} className="header-select-drop"
                      value={this.state.selectedPartner || selectedPartnerName}
                      onChange={this.partnerHandleChange} showSearch={true} style={{ width: '100%' }} >
                      {
                        this.props?.partners && this.props?.partners.filter(partner => partner.linkPartnerBO.name !== undefined)?.map(partner => (
                          <Option key={partner.linkPartnerBO.id}>
                            {partner.linkPartnerBO.name}
                          </Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                }
                <Form.Item label="Partner Location">
                  <Select loading={this.props.locationLoading} notFoundContent="Partner Location List not present"
                    filterOption={filterSelectOptions} className="header-select-drop"
                    value={this.state.selectedPartnerLocation || selectedPartnerLocationName}
                    onChange={this.partnerLocationhandleChange}
                    showSearch={true} style={{ width: '100%' }} >
                    {
                      this.props?.partnerLocations?.map(location => (
                        <Option key={location.id}>{location.name}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Form>
            </Spin>
          </Modal>

          <Modal onCancel={this.handleCancel} maskClosable={false}
            visible={this.state.confirmModalVisible} title="Confirmation"
            onOk={this.confirmed} okText={"Yes"} cancelText={"No"}
            centered={true} destroyOnClose={true} confirmLoading={this.state.confirmLoader} >
            <h3>Are you sure?</h3>
            <p>Do you want to Reload {this.state.selectedConfirmModal} of the system?</p>
          </Modal>

          <TrackingPopupModal onCancel={this.handleCancel} visible={this.state.showTrackingPopup} input={this.state.searchedWaybill} waybillNo={this.state.searchedWaybill}
            source={Constants.TRACKING_CLASSNAME} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: state.header?.loggedInUser,
  partners: state.header?.partnerHeaderDetails?.partners,
  partnerLocations: state.header?.locationHeaderDetails,
  login: state.header?.login,
  partnerLoading: state.header?.partnerLoading,
  locationLoading: state.header?.locationLoading,
  userInfo: state.header?.userInfo,
  refreshApp: state.header?.refreshApp,
  waybillDetails: state.tracking?.waybillDetails,
  source: state.tracking?.source,
  configurations: state.app.configurations,
  permissions: state.app.permissions,
  isWaybillModalOpen: state.header.isWaybillModalOpen,
  waybillInput: state.header.waybill
});
const mapDispatchToProps = dispatch => ({
  getLoggedInUserInfo: () => dispatch(actions.getLoggedInUserInfo()),
  getPartnerLocatinDetails: params =>
    dispatch(actions.getPartnerLocatinDetails(params)),
  getPartnerLocatinDetailsV2: params =>
    dispatch(actions.getPartnerLocatinDetailsV2(params)),
  fetchPartnerLocatinDetails: (params, partnerId) => dispatch(actions.fetchPartnerLocatinDetails(params, partnerId)),
  getPartnerDetails: params => dispatch(actions.getPartnerDetails(params)),
  appLogin: param => dispatch(actions.appLogin(param)),
  updateLocalItemInRedux: param =>
    dispatch(actions.updateLocalItemInRedux(param)),
  storeGetDataLocalStorage: () => dispatch(actions.storeGetDataLocalStorage()),
  getWaybillTrackingDetails: param =>
    dispatch(
      trackingActions.getWaybillTrackingDetails(param.waybillNo, param.source)
    ),
  userLogout: () => dispatch(actions.userLogout()),
  getPermissions: () => dispatch(getPermissions()),
  toggleWaybillModal: (waybill, isOpen = false) => dispatch(actions.openWaybillModal(waybill, isOpen)),
  getConfigurations: () => dispatch(getConfigurations())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
