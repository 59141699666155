import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const RtoWaybill = () => {

    return (

        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">RTO - Waybill</div>

            <MetabaseWidget text={"RTO - Pending RTO Waybill Count"}
                className={"flex-1 flex-box justify-content-center width-30"}
                reportJson={{
                    questionNo: 2062, reportName: "rto-pending-rto-waybill-count",
                    lockedParams: ["location_id"], height: "280px"
                }} />

            <MetabaseWidget text={"RTO - Pending RTO Waybill"}
                className={"flex-1 flex-box justify-content-center"}
                reportJson={{
                    questionNo: 2008, reportName: "rto-pending-rto-waybill",
                    lockedParams: ["location_id"], height: "400px"
                }} />


        </div>

    )

}

export default RtoWaybill;