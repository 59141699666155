import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const ReversePickupReport = () => {

    return (
        <div className="flex-column flex-gap-xl" style={{ paddingBottom: 24 }}>

            <div className="font-size-m-3 grey-text"> <strong>Note:</strong>The date filter is applied only on PICKUP OPERATIONS MONITORING reports.</div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">Pickup Planning Dashboard</div>

                <MetabaseWidget text={"Pickup planning dashboard"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2203, reportName: "pickup-planning-dashboard",
                        lockedParams: ["location_id"], height: "400px"
                    }} />
            </div>


            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">Pickup Performance Dashboard</div>

                <MetabaseWidget text={"RVP Pickup performance dashboard"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2204, reportName: "rvp-pickup-performance-dashboard",
                        lockedParams: ["location_id"], height: "400px"
                    }} />
            </div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">Pickup Operations Monitoring Dashboard</div>

                <MetabaseWidget text={"FE level performance"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2205, reportName: "fe-level-performance",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

                <MetabaseWidget text={"LM - RVP PRS report"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2628, reportName: "lm-rvp-prs-report",
                        lockedParams: ["location_id"], height: "400px"
                    }} />
            </div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">Hub Pendency dashboard</div>

                <MetabaseWidget text={"Hub Pendency dashboard"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2207, reportName: "hub-pendency-dashboard",
                        lockedParams: ["location_id"], height: "400px"
                    }} />
            </div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">Data Dumps</div>

                <MetabaseWidget text={"Dump for Pickup planning dashboard"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2213, reportName: "dump-for-pickup-planning-dashboard",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

                <MetabaseWidget text={"Dump for Pickup performance dashboard"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2214, reportName: "dump-for-pickup-performance-dashboard",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

                <MetabaseWidget text={"Dump for hub pendency dashboard"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2215, reportName: "dump-for-hub-pendency-dashboard",
                        lockedParams: ["location_id"], height: "400px"
                    }} />
            </div>

        </div>
    )

}

export default ReversePickupReport;