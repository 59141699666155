import React from "react";
import MetabaseWidget from "../../components/metabase.widget";

const FeMonitoring = () => {

    return (
        <div className="flex-column flex-gap-xl" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">FM PICKUP OPERATIONS MONITORING DASHBOARD</div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">Location Level Pickup Performance</div>

                <MetabaseWidget text={"Pickup Report - Location level"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1750, reportName: "pickup-report-location-level",
                        lockedParams: ["location_id"], height: "400px"
                    }} />
            </div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">FE Level Performance</div>

                <MetabaseWidget text={"FE level Performance"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2184, reportName: "fe-level-performance",
                        lockedParams: ["location_id"], height: "400px"
                    }} />
            </div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">FE Level Seller performance</div>

                <MetabaseWidget text={"FE Seller Level Performance"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2185, reportName: "fe-seller-level-performance",
                        lockedParams: ["location_id"], height: "400px"
                    }} />
            </div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">Data Dump for Pickups Assigned</div>

                <MetabaseWidget text={"Pickup Report"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1749, reportName: "pickup-report",
                        lockedParams: ["location_id"], height: "400px"
                    }} />
            </div>

        </div>
    )

}

export default FeMonitoring;