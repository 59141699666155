import React from "react";
import MetabaseWidget from "../../components/metabase.widget";

const ForwardPickupPlanning = () => {

    return (
        <div className="flex-column flex-gap-xl" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">FM PICKUP PLANNING DASHBOARD</div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">Pickup Pendency Report</div>

                <MetabaseWidget text={"FM Pendency Overview"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2176, reportName: "fm-pendency-overview",
                        lockedParams: ["location_id"], height: "250px"
                    }} />
            </div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-l-3 grey-text">Seller Pendency Report</div>

                <MetabaseWidget text={"FM Seller report"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2177, reportName: "fm-seller-report",
                        lockedParams: ["location_id"], height: "500px"
                    }} />
            </div>

        </div>
    )

}

export default ForwardPickupPlanning;
