import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InfoCard from "./components/info-card";
import ScanCard from "./components/scan-input";
import ValidateResyncCard from "./components/validate-resync-card";
import "./pod-drs-closure.styles.scss";
import { useNavigate } from "react-router-dom";
import CloseDrsModal from "./components/close-drs-modal";
import MonitoringReportsService from "../../../services/monitoring-report/monitoring-report.service";
import { Constants } from "../../../common/utils/constants";
import MetabaseWidget from "../../metabase-dashboards/components/metabase.widget";

const PodDrsClosureV2 = () => {

    const [loading, setLoading] = useState(false);
    const [validateResInfo, setValidateResInfo] = useState({})
    const [modalDataObj, setModalDataObj] = useState({})
    const [openCloseDrsModal, setOpenCloseDrsModal] = useState(false)
    const [toggleInputClear, setToggleInputClear] = useState(false)
    const [showScanCard, setShowScanCard] = useState(false);

    const config = useSelector((state) => state?.app?.configurations)
    const navigate = useNavigate();

    const toggleLoader = (loading) => {
        setLoading(loading)
    }

    const handleValidateRes = (validateResData) => {
        setModalDataObj(validateResData?.feDetails ?? {})
        setValidateResInfo(validateResData?.formattedRes ?? {})
        if (validateResData?.formattedRes?.undelShipment?.length > 0 ||
            validateResData?.formattedRes?.ofdShipment?.length > 0) {
            setShowScanCard(true)
        } else {
            setShowScanCard(false)
        }
    }

    const handleScanRes = (waybillNo) => {

        // Removing waybill that has already been scanned.
        let undelShipmentList = validateResInfo.undelShipment.filter(waybillObj => waybillObj.waybillNo !== waybillNo)

        // Opening close drs modal when all the undel shipments are scanned
        // and there are no ofd shipments
        // if (undelShipmentList?.length == 0 && validateResInfo?.ofdShipment?.length == 0) {
        //     handleTriggerCloseDrsModal()
        // }

        setValidateResInfo(prevObject => ({ ...prevObject, undelShipment: undelShipmentList }))

    }

    const handleTriggerCloseDrsModal = (modalData) => {
        if (modalData) {
            setModalDataObj(modalData)
        }
        setOpenCloseDrsModal(true)
    }

    const handleModalClose = () => {
        setOpenCloseDrsModal(false)
    }

    const handleModalConfirm = () => {
        setToggleInputClear(!toggleInputClear)
        setOpenCloseDrsModal(false)
    }

    return (
        <>
            <Spin spinning={loading}>
                <div className="flex-box flex-column flex-gap-xl">
                    <div>
                        <ValidateResyncCard toggleLoader={toggleLoader} handleValidateRes={handleValidateRes}
                            triggerCloseDrsModal={handleTriggerCloseDrsModal} clearInput={toggleInputClear} />
                    </div>
                    {showScanCard ?
                        <>
                            <div>
                                <ScanCard toggleLoader={toggleLoader} handleScanRes={handleScanRes} validateFeRes={validateResInfo} />
                            </div>
                            <div>
                                <InfoCard displayData={validateResInfo} feDetails={modalDataObj} />
                            </div>
                        </>
                        :
                        <div className="white-bg" style={{ height: "calc(100vh - 300px)", marginBottom: 25 }}>
                            <MetabaseWidget text={"OPEN DRS Summary - FE Level report"}
                                className={"flex-1 flex-box justify-content-center"}
                                reportJson={{
                                    questionNo: 4849, reportName: "open-drs-summary-fe-level-report",
                                    lockedParams: ["location_id", "partner_id"], height: "450px"
                                }} />
                        </div>
                    }
                    {/* {showScanCard &&
                        <div>
                            <ScanCard toggleLoader={toggleLoader} handleScanRes={handleScanRes} validateFeRes={validateResInfo} />
                        </div>
                    }
                    {(validateResInfo?.undelShipment?.length > 0 || validateResInfo?.ofdShipment?.length > 0) &&
                        <div>
                            <InfoCard displayData={validateResInfo} feDetails={modalDataObj} />
                        </div>

                    } */}
                </div>

                <CloseDrsModal open={openCloseDrsModal} handleModalClose={handleModalClose} handleModalConfirm={handleModalConfirm}
                    modalData={modalDataObj} toggleLoader={(value) => toggleLoader(value)} />

            </Spin >

        </>
    )
}

export default PodDrsClosureV2;