export default () => {
    let s3ReportInterval = '',
        s3IntevalCounter = 0,
        currentCounter = 0;
        
    let checkS3FileStatus = (data) => {
        let s3Url = data.s3Url,
            xhttp = new XMLHttpRequest();

        if (!s3Url) {
            console.warn('No S3 URL found for report')
            return;
        }

        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                postMessage({ 'type': 'reportDownloadSuccess', 'link': s3Url });
                clearRequestedInterval(0)
                clearRequestedInterval(1)
                clearRequestedInterval(s3ReportInterval);
            } else {
                if (currentCounter === s3IntevalCounter) {
                    clearRequestedInterval(0)
                    clearRequestedInterval(1)
                    clearRequestedInterval(s3ReportInterval);
                    s3IntevalCounter = 0;
                    currentCounter = 0;
                    postMessage({ 'type': 'reportDownloadFailed', 'value': currentCounter });
                    postMessage({ 'type': 'storeCounterValue', 'value': 0 });
                } else if (this.readyState === 4) {
                    currentCounter++;
                    postMessage({ 'type': 'storeCounterValue', 'value': currentCounter });
                }
            }
        };

        xhttp.open("GET", s3Url, true);
        xhttp.send();
    }

    let clearRequestedInterval = data => {
        clearInterval(data);
    }
    
    self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
        if (e.data.invokeType === 'report_s3_url') {
            if (e.data.s3Url) {
                let data = e.data;
                s3IntevalCounter = e.data.counterLimit;

                checkS3FileStatus(data);

                s3ReportInterval = setInterval(() => {
                    checkS3FileStatus(data);
                }, e.data.intervalTime);
            }
        }
    })
}