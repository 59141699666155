import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const ScVisibilityRto = () => {

    return (
        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">Sort Center - Visibility  - RTO</div>


            <div className="flex-column flex-gap-xl">

                <div
                    className="metabase-subheader-container font-size-l-1 border-radius-m">
                    Today's Summary
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC - RTO - Shipments Arriving"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1206, reportName: "sc-rto-shipments-arriving",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC -  RTO - Bags Arriving"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1207, reportName: "sc-rto-bags-arriving",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC -  RTO - In-scans Today"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1208, reportName: "sc-rto-in-scans-today",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC -  RTO - Shipments In Scanned and not Bagged"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1209, reportName: "sc-rto-shipments-in-scanned-and-not-bagged",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC -  RTO - Shipments In Bagged and not Shipped"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1210, reportName: "sc-rto-shipments-in-bagged-and-not-shipped",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>

            </div>

            <div className="flex-column flex-gap-xl">
                <div
                    className="metabase-subheader-container font-size-l-1 border-radius-m">
                    Time Buckets
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC - RTO - Received to Bagged - Time Taken Bucketed"}
                        className={"flex-3 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1213, reportName: "sc-rto-received-to-bagged-time-taken-bucketed",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC - RTO - Received to Bagged - Time Taken Average"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1215, reportName: "sc-rto-received-to-bagged-time-taken-average",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC - RTO - Bagged to Shipped - Time Taken Bucketed"}
                        className={"flex-3 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1218, reportName: "sc-rto-bagged-to-shipped-time-taken-bucketed",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"SC - RTO - Received to Bagged - Time Taken Average"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1215, reportName: "sc-rto-received-to-bagged-time-taken-average",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>

            </div>

            <div className="flex-column flex-gap-xl">
                <div
                    className="metabase-subheader-container font-size-l-1 border-radius-m">
                    Data Dump
                </div>

                <MetabaseWidget text={"Action Pendency - Data Dump - RTO"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1262, reportName: "action-pendency-data-dump-rto",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

                <MetabaseWidget text={"RTO - Lane Wise Details - Sort Center"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 1326, reportName: "rto-lane-wise-details-sort-center",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

            </div>

        </div>
    )

}

export default ScVisibilityRto;