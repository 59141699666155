import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const ScRtoDailyActions = () => {

    return (
        <div className="flex-column flex-gap-xl" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">SC | Daily Action File - RTO View</div>

            <div className="flex-column flex-gap-l">

                <div className="font-size-l-3 grey-text text-align-center">Inbound or Arrived Trips</div>

                <MetabaseWidget text={"SC - Inbound/ RTO En route"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2399, reportName: "sc-inbound-rto-en-route",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

                <MetabaseWidget text={"SC - Inbound/ RTO Arrived"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2400, reportName: "sc-inbound-rto-arrived",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

                <MetabaseWidget text={"SC - Inbound/ RTO Arrived - Past Incomplete"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2402, reportName: "sc-inbound-rto-manifest-received",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

            </div>


            <div className="flex-column flex-gap-l">

                <div className="font-size-l-3 grey-text text-align-center">Inbound or Arrived Trips</div>

                <MetabaseWidget text={"SC - Inbound/ RTO Manifest Received"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2402, reportName: "sc-inbound-rto-manifest-received",
                        lockedParams: ["location_id"], height: "400px"
                    }} />


                <MetabaseWidget text={"SC - Inbound/ RTO Manifest Received - Past Incomplete"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2403, reportName: "sc-inbound-rto-manifest-received-past-incomplete",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

            </div>

            <div className="flex-column flex-gap-l">

                <div className="font-size-l-3 grey-text text-align-center">Sort Center Bagging Pendency View</div>

                <MetabaseWidget text={"Lanewise - Shipment Pendency in Day Buckets"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2404, reportName: "lanewise-shipment-pendency-in-day-buckets",
                        lockedParams: ["location_id"], height: "400px"
                    }} />


                <MetabaseWidget text={"Lanewise - Shipment Pendency"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2405, reportName: "lanewise-shipment-pendency",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

                <MetabaseWidget text={"Lanewise - Manifest Created not Connected"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2406, reportName: "lanewise-manifest-created-not-connected",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

            </div>

            <div className="flex-column flex-gap-l">

                <div className="font-size-l-3 grey-text text-align-center">Outbound RTO Trip Dispatched</div>

                <MetabaseWidget text={"Outbound/ RTO Trips Dispatched"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 2407, reportName: "outbound-rto-trips-dispatched",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

            </div>

            <div className="flex-column flex-gap-l">

                <div className="font-size-l-3 grey-text text-align-center">Data Dump</div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"Shipment Pendency - Data Dump"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 2408, reportName: "shipment-pendency-data-dump",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC - Inbound/ RTO En route - Data Dump"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 2411, reportName: "sc-inbound-rto-en-route-data-dump",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                    <MetabaseWidget text={"SC - Inbound/ RTO Arrived - Data Dump"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 2409, reportName: "sc-inbound-rto-arrived-data-dump",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                    <MetabaseWidget text={"SC - Inbound/ RTO Arrived - Past Incomplete - Data Dump"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 2410, reportName: "sc-inbound-rto-arrived-past-incomplete-data-dump",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"SC - Inbound/ RTO Manifest Received - Past Incomplete - Data Dump"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 2417, reportName: "sc-inbound-rto-manifest-received-past-incomplete-data-dump",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                    <MetabaseWidget text={"SC - Inbound/ RTO Manifest Received - Data Dump"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 2416, reportName: "sc-inbound-rto-manifest-received-data-dump",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                    <MetabaseWidget text={"Lanewise - Shipment Pendency - Data Dump"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 2414, reportName: "lanewise-shipment-pendency-data-dump",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"Lanewise - RTO Manifest Created not Connected - Data Dump"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 2418, reportName: "lanewise-rto-manifest-created-not-connected-data-dump",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                    <MetabaseWidget text={"Outbound/ RTO Trips Dispatched - Data Dump"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 2419, reportName: "outbound-rto-trips-dispatched-data-dump",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                </div>

            </div>

        </div>
    )

}

export default ScRtoDailyActions;