import { Divider } from 'antd';
import bwipjs from 'bwip-js';
import moment from 'moment-timezone';
import React, { useEffect } from "react";
import Barcode from '../../ui/barcode';

const EcomLabelTemplate = React.forwardRef(({ data, flag }, ref) => {

    useEffect(() => {
        // The return value is the canvas element
        bwipjs.toCanvas('mycanvas', {
            bcid: 'datamatrix',       // Barcode type
            text: data?.waybillNo,    // Text to encode
            scale: 3,               // 3x scaling factor
            height: 8,              // Bar height, in millimeters
            includetext: true,            // Show human-readable text
            textxalign: 'center',        // Always good to set this
        });
    }, [data]);

    return (
       <div className='flex-column flex-gap-m justify-content-space-between' style={{ fontSize : 12 }} ref={ref}>
            
            <div className='flex-box'>
                <div className='flex-1'>
                    <Barcode width={3} height={6} format="CODE128" value={data?.waybillNo}
                     showText={true} barCodeId="waybillNo" />
                </div>
                <div className='flex-1 flex-box'>
                    <canvas id="mycanvas" className='canvas-align'></canvas>
                    <div className='font-size-l-1'>RVP</div>
                </div>
            </div>

            <div className='flex-box flex-gap-m'>
                <div className='flex-1 flex-column flex-gap-s justify-content-space-between'>
                    <div className='flex-column flex-gap-m'>
                        <strong> Ship To: </strong>
                        <strong> {data.sellerName} </strong>
                        <div> {data.shipperAddress} </div>
                        <div className='flex-box' style={{ marginTop : 12 }}>
                            <div> Seller GSTIN : </div> <div> {data.sellerGst} </div>
                        </div>
                        <div className='flex-box'>
                            <div> Product Description : </div> <div> {data.description} </div>
                        </div>
                        <div className='flex-box'>
                            <div> Quantity : </div> <div> {data.quantity || 1} </div>
                        </div>
                    </div>
                    <div className='flex-box flex-gap-m justify-content-space-between'>
                        <div className='flex-box flex-gap-m flex-1'>
                            <div> Employee Code : </div> <div> {data.employeeCode} </div>
                        </div>
                        <div className='flex-box flex-gap-m flex-1'>
                            <div> Time : </div> <div> {moment().format("YYYY-MM-DD HH:MM:SS")} </div>
                        </div>
                    </div>
                </div>
                <div style ={{
                    height : "300px",
                    width  : "5px",
                    backgroundColor : "black",
                    borderRadius : "20px"
                }}></div>
                <div className='flex-1 flex-column flex-gap-m'>
                    <strong> Ship From: </strong>
                    <strong> {data.consigneeName} </strong>
                    <div> {data.consigneeAddress} </div>
                    <div className='flex-box' style={{ marginTop : 12 }}>
                        <div> Invoice Value : </div> <div> {data.orderValue || data.consignmentAmount} </div>
                    </div>
                    <div className='flex-box'>
                        <div> Taxable Value : </div> <div> {data.orderValue || data.consignmentAmount} </div>
                    </div>
                    <div className='flex-box'>
                        <div> GST Amount : </div> <div> {data.gst} </div>
                    </div>
                    <div className='flex-box'>
                        <div> Invoice No : </div> <div> {data.invoiceNumber || 0} </div>
                    </div>
                    <div className='flex-box'>
                        <div> Invoice Date : </div> <div> {moment(data.bookingDate).format("DD MMM 'YY")} </div>
                    </div>
                </div>
                <div style={{ position : "relative" }}>
                    <div className='flex-column align-items-center flex-gap-l' style={{ 
                        position : "absolute", 
                        transform : "rotate(-90deg)",
                        left: "-100px",
                        right : 0,
                        top : "32.5%"
                    }}>
                        <div>Customer Order Id</div>
                        <Barcode height={12} width={1} format="CODE128" value={data?.parentOrderId}
                        showText={true} barCodeId="customerOrderId" />
                    </div>
                </div>
            </div>

            <div>
                <strong>Disclaimer</strong> : Ecom Express Pvt Ltd. Is merely a courier service provider. For any query related to product or sale of product please contact seller/shipper/e-commerce-website
            </div>
       </div>
    )

});
export default EcomLabelTemplate;