import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const IppDashboard = () => {

    return (
        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">Individual Performance Plan - Dashboard Test</div>

            <div className="flex-column flex-gap-xl">
                <div
                    className="metabase-subheader-container font-size-l-1 border-radius-m">
                    Performance over the last 3 hours. All trends are based on the same 3 hours of the previous day
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"Primary Scans IPP Test"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 4360, reportName: "primary-scans-ipp-test",
                            lockedParams: ["location_id"], height: "300px",
                            autoRefresh: 60
                        }} />
                    <MetabaseWidget text={"Bag Scan IPP Test"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 4361, reportName: "bag-scan-ipp-test",
                            lockedParams: ["location_id"], height: "300px",
                            autoRefresh: 60
                        }} />
                    <MetabaseWidget text={"Total Scans - IPP Test"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 4359, reportName: "total-scans-ipp-test",
                            lockedParams: ["location_id"], height: "300px",
                            autoRefresh: 60
                        }} />
                </div>

                <div className="flex-column flex-gap-xl">
                    <div
                        className="metabase-subheader-container font-size-l-1 border-radius-m">
                        Performance of users over the last 3 hours. Only, Users with more than 10 scans are considered.
                    </div>

                    <div className="flex-box flex-gap-m justify-content-center">
                        <MetabaseWidget text={"Leader Board - Top 10 IPP Test"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4357, reportName: "leader-board-top-10-ipp-test",
                                lockedParams: ["location_id"], height: "400px",
                                autoRefresh: 60
                            }} />
                        <MetabaseWidget text={"Leader Board - Last 10 IPP Test"}
                            className={"flex-1 flex-box justify-content-center"}
                            reportJson={{
                                questionNo: 4358, reportName: "leader-board-last-10-ipp-test",
                                lockedParams: ["location_id"], height: "400px",
                                autoRefresh: 60
                            }} />
                    </div>
                </div>

            </div>

        </div>
    )

}

export default IppDashboard;