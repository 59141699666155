import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { billingQueryReference } from "./constants";
import MetabaseQuestion from "../common/metabase-question/metabase.question";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import MetabaseWidget from "../metabase-dashboards/components/metabase.widget";

export default function BillingModule() {

    const location = useLocation();
    const [reportReferenceJSON, SetReportReferenceJSON] = useState(undefined);
    const userInfo = useSelector(state => state?.header?.userInfo);

    useEffect(_ => {


        let locationOpsType = "LM"

        if (userInfo?.currentPartnerLocationOpsType) {
            locationOpsType = userInfo?.currentPartnerLocationOpsType
        } else if (localStorage.currentPartnerLocationOpsType &&
            localStorage.currentPartnerLocationOpsType !== 'null' &&
            localStorage.currentPartnerLocationOpsType !== 'undefined') {
            locationOpsType = localStorage.currentPartnerLocationOpsType
        }

        // const locationOpsType = localStorage.user ? JSON.parse(atob(localStorage.user)).location.locationOpsType || "LM" : "LM"

        let __reportReferenceJSON = undefined;
        if (locationOpsType && location.pathname.indexOf("forward") > -1) {
            __reportReferenceJSON = billingQueryReference[(locationOpsType + "_FORWARD")];
        }
        else if (locationOpsType && location.pathname.indexOf("rto") > -1) {
            __reportReferenceJSON = billingQueryReference[(locationOpsType + "_RTO")];
        }
        else if (location.pathname.indexOf("cross-dock") > -1) {
            __reportReferenceJSON = billingQueryReference.SC_CROSSDOCK;
        }
        else if (location.pathname.indexOf("rvp-rider-wise") > -1) {
            __reportReferenceJSON = billingQueryReference.RVP_RIDER_WISE;
        }

        if (location?.search?.length > 0) {
            const queryParams = new URLSearchParams(location.search);
            if (queryParams?.size == 1 && queryParams.has("date_params")) {
                const dateParamsSplit = queryParams.get("date_params").split(",")
                __reportReferenceJSON['dateParams'] = new Set()

                if (dateParamsSplit.includes('start_date')) {
                    __reportReferenceJSON.dateParams.add('startDate')
                }

                if (dateParamsSplit.includes('end_date')) {
                    __reportReferenceJSON.dateParams.add('endDate')
                }
            }

        }

        SetReportReferenceJSON(__reportReferenceJSON);

    }, [location.pathname])


    return (
        reportReferenceJSON !== undefined ?
            <MetabaseWidget {...reportReferenceJSON}
                className={"flex-1 flex-box justify-content-center"} /> :
            <Spin spinning={true}></Spin>
    );
}