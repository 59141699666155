import { Button, Divider, Spin, Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../../common/utils/constants";
import ExceptionsMenu from "./components/exceptions.menu";
import ExceptionsScan from "./components/exceptions.scan";
import ExceptionsTable from "./components/exceptions.table";
import ManifestExceptions from "./components/manifest.exceptions";
import { fetchExceptions } from "./services/fetch.service";
import { ACTION_CONSTANTS } from "./state/reducer";
import MonitoringReportsService from "../../../services/monitoring-report/monitoring-report.service";
import ExceptionsData from "../../metabase-dashboards/inventory/exceptions-data";
import MetabaseWidget from "../../metabase-dashboards/components/metabase.widget";

export default function Exceptions() {

    const defaultPayload = {
        exceptionType: [],
        movementType: [],
        entityType: 'WAYBILL',
        status: ['NEEDS_ACTION'],
        startDate: moment().startOf("day").valueOf(),
        endDate: moment().endOf("day").valueOf(),
        locationId: Constants.userLocationId,
        pageNo: 1,
        pageSize: 10
    }

    const [loading, SetLoading] = useState(false);
    const [filterNotApplied, setFilterNotApplied] = useState(true);
    const [filterObject, SetFilterObject] = useState({ ...defaultPayload });
    const [exceptionEntityType, SetExceptionEntityType] = useState('consignment')

    const exceptionResponseObject = useSelector(state => state.exceptions?.entityExceptions);
    const dispatch = useDispatch();

    const loadExceptionList = params => {
        setFilterNotApplied(false)
        SetLoading(true);
        SetFilterObject(params);
        fetchExceptions(params)
            .then(exceptionResponse => {
                dispatch({
                    type: ACTION_CONSTANTS.STORE_EXCEPTIONS,
                    payload: exceptionResponse
                });
                SetLoading(false);
            })
            .catch(e => console.log(e))
    }

    const afterScan = params => {
        setFilterNotApplied(true)
    }

    //useEffect(() => loadExceptionList({ ...defaultPayload }), []);

    const onFilterChange = updatedObject => {
        setFilterNotApplied(true)
        SetFilterObject({ ...filterObject, ...updatedObject });
        // loadExceptionList({
        //     ...filterObject,
        //     ...updatedObject,
        //     pageNo : 1
        // });
    };


    const reloadListWithFilters = _ => afterScan({ ...filterObject });

    const resetFilters = _ => loadExceptionList({ ...defaultPayload });
    const onFilterSubmit = _ => loadExceptionList({ ...filterObject });

    return (

        <div className="flex-column flex-gap-s white-bg">
            <Tabs className="log10-tabs" activeKey={exceptionEntityType} onChange={SetExceptionEntityType}>
                <Tabs.TabPane tab={<span className="text-semi-bold">{"Waybill"}</span>} key={"consignment"} />
                <Tabs.TabPane tab={<span className="text-semi-bold">{"Manifest"}</span>} key={"manifest"} />
            </Tabs>
            {
                exceptionEntityType == 'consignment' ?
                    <div className="flex-column flex-gap-l">
                        <ExceptionsScan reloadListWithFilters={reloadListWithFilters} />

                        <div className="divider-container">
                            <Divider />
                        </div>

                        <div className="white-bg"
                            style={{ height: "calc(100vh - 300px)", marginBottom: 25 }}>
                            <MetabaseWidget text={"Exceptions - Utility"}
                                className={"flex-1 flex-box justify-content-center"}
                                reportJson={{
                                    dashboardNo: 398, reportName: "exceptions-utility",
                                    lockedParams: ["location_id"], height: "400px"
                                }} />
                        </div>

                        {/* TODO: Modify and use this in case loading entire dashboard is slow... */}
                        {/* <div className="p-20 primary-div-container"
                            style={{ height: "calc(100vh - 250px)", overflow: 'auto' }}>
                            <ExceptionsData />
                        </div> */}

                        {/* <ExceptionsMenu filterObject={filterObject}
                    onFilterChange={onFilterChange} resetFilters={resetFilters} />
                       <Spin spinning={filterNotApplied || loading} 
        indicator={filterNotApplied ? <div><Button size="large" type="primary" onClick={onFilterSubmit}> Apply Filters </Button></div> : undefined} 
        >
                    <ExceptionsTable exceptionResponseObject={exceptionResponseObject} filterObject={filterObject}
                    onPageChange={pageNo => {
                        loadExceptionList({
                            ...filterObject,
                            pageNo : pageNo || 1
                        })
                    }} />
                    </Spin> */}

                    </div> :
                    exceptionEntityType == 'manifest' ? <ManifestExceptions /> :
                        <></>
            }
        </div>

    )
}