import React, { useEffect, useRef, useState } from "react";
import {
    Card,
    Table,
    Tag
} from "antd";
import moment from "moment";
import { capitalizeString, canAllow } from "../../../common/utils/utils.js";
import { permissionTags } from "../../../configs/permissionTags.js";
import { getPODLiveNestedTableColumnsLegacy, getPODLiveNestedTableColumns } from "./pod-live.utils.js";
import DRSFilters from "../../common/drs-filter";
import * as PodLiveService from "./service/index";
import PodTimeline from "./pod.timeline.js";
import { Constants } from "../../../common/utils/constants.js";

const PODLiveTable = ({
    data,
    getPaginatedData,
    pageNo,
    PaginatedPODLive,
    applyPagination,
    livePermission = {
        create: canAllow(permissionTags.pod.drs.create),
    },
    history,
    configPodLive,
    tableLoader
}) => {

    const [drsDataList, setDrsDataList] = useState([])

    const columns = [
        {
            title: "DRS ID",
            dataIndex: "drsCode",
            key: "drsCode",
        },
        {
            title: "AWB",
            dataIndex: configPodLive ? "waybillCount" : "consignments",
            key: "AWBC",
            width: 75,
            render: (data, row) => {
                if (configPodLive) {
                    return <div>{data}</div>
                } else {
                    return (<div>{row?.consignments ? row.consignments.length : 0}</div>)
                }
            }
        },
        {
            title: "Employee",
            dataIndex: "drsUser",
            key: "drsUser",
            application: "B2C",
            render: (data, row) => {
                if (configPodLive) {
                    return (
                        <span>{data.name ? data.name : 'N/A'}<br /> <small>{data.number ? data.number : 'N/A'}</small></span>
                    )
                } else {
                    return (
                        <span>{data?.name ? data?.name : 'N/A'}<br /> <small>{data?.contactNumber ? data?.contactNumber : 'N/A'}</small></span>
                    )
                }
            }
        },
        {
            title: "Cash Summary",
            dataIndex: "collectedCodAmount",
            key: "collectedCodAmount",
            application: "B2C",
            render: (data, row) => (<div>{row.totalCodAmount !== 0 ? <span>&#x20B9; </span> : <span></span>}{row.collectedCodAmount == null ? "0/" + row.totalCodAmount : row.collectedCodAmount + "/" + row.totalCodAmount}</div>)
        },
        {
            title: "Del/UnDel/UnAttempted",
            dataIndex: configPodLive ? "undelCount" : "consignments",
            key: configPodLive == 'true' ? "undelCount" : "consignments",
            application: "B2C",
            render: (data, row) => {
                if (configPodLive) {
                    return (<div>{(row?.delCount ? row.delCount : "0") + "/" + (row?.undelCount ? row.undelCount : "0") + "/" + (row?.unAttemptedCount ? row.unAttemptedCount : "0")}</div>)
                } else {
                    return (<div>{row?.consignments && row?.customObj ? row?.customObj?.totalDel + "/" + row?.customObj?.totalUnDel + "/" + (row.consignments.length - row.customObj.totalDel - row.customObj.totalUnDel) : "0/0/0"}</div>)
                }
            }
        },
        {
            title: "Created On",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (data, row) => <div>{moment(data).format("DD/MM/YYYY hh:mm A")} <br /> <small>{moment(row.createdAt).fromNow()}</small></div>

        },
        {
            title: "Status",
            dataIndex: "drsStatus",
            key: "drsStatus",
            width: 175,
            render: (status, row) => {
                let tagClassName = "info-tag";
                switch (status) {
                    case "CLOSED": tagClassName = "success-tag"; break;
                    case "PENDING":
                    case "DRAFT":
                    case "OPEN": tagClassName = "error-tag"; break;
                    case "HANDED_OVER": tagClassName = "secondary-info-tag"; break;
                    case "COMPLETED": tagClassName = "warning-tag"; break;
                    case "IN_TRANSIT": tagClassName = "info-tag"; break;
                    default: return "";
                }
                if (tagClassName) {
                    return (
                        <Tag className={"ant-table-tag text-align-center " + tagClassName}>
                            {
                                (status.split("_").map(s => capitalizeString(s.toLowerCase()))).join(" ")
                            }
                        </Tag>
                    )
                }
            }
        }
    ];

    useEffect(() => {
        if (PaginatedPODLive) {
            setDrsDataList(PaginatedPODLive[pageNo])
        }
    }, [PaginatedPODLive])

    const expandedRowRender = (row, t, i, expanded) => {

        if (expanded) {

            let column2 = []

            if (!row?.consignments?.length) {
                return null
            }

            if (configPodLive) {

                column2 = getPODLiveNestedTableColumns({
                    livePermission,
                    drsId: row.drsId,
                    rowData: row,
                    drsStatus: row.drsStatus,
                    drsCode: row.drsCode,
                }, history)

            } else {

                column2 = getPODLiveNestedTableColumnsLegacy({
                    livePermission,
                    rowId: row.id,
                    rowData: row,
                    drsStatus: row.drsStatus,
                    drsCode: row.drsCode,
                }, history)

            }

            return (
                <div className="flex-column">
                    {
                        Constants.PARTNER_ID == 127808 && row.consignments?.length > 1 &&
                        <PodTimeline drs={row}/>
                    }
                    <Table size="middle" columns={column2} dataSource={row.consignments} pagination={false} />
                </div>
            )
        }

        return null
    };

    const handleExpanded = (expanded, record) => {
        if (expanded && configPodLive) {
            const filter = {
                drsCodes: [record.drsCode]
            }
            PodLiveService.fetchPODList({ filters: filter })
                .then((data) => {
                    // drsData.drsId == record.drsId
                    let expandedRowIndex = drsDataList.findIndex((drsData) => drsData.drsId == record.drsId)
                    let drsDataListCopy = JSON.parse(JSON.stringify(drsDataList));

                    drsDataListCopy[expandedRowIndex] = data.deliveryRunSheets.length > 0 ? data.deliveryRunSheets[0] : drsDataListCopy[expandedRowIndex]

                    setDrsDataList(drsDataListCopy)
                })
                .catch((error) => {
                    console.error("fetchPODList failed with error -> ", error)
                })
        }
    }

    const onFormSubmit = (filters, reset) => applyPagination(filters, reset)

    const actionBtns = (
        <div className="flex-box flex-gap-l">
            <DRSFilters onFormSubmit={onFormSubmit} />
        </div>
    );

    return (
        <Card title={<h2>POD-AWB List</h2>} extra={actionBtns}>
            <Table bordered={true} size={"middle"}
                rowKey={row => row.id}
                columns={columns}
                defaultExpandAllRows={false}
                onExpand={handleExpanded}
                expandedRowRender={(record, index, indent, expanded) => expandedRowRender(record, index, indent, expanded)}
                pagination={{
                    onChange: getPaginatedData,
                    total: data.totalCount,
                    position: "bottom",
                    current: pageNo,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    pageSize: data.pageSize
                }}
                dataSource={drsDataList}
                scroll={{ x: 800 }}
                loading={tableLoader}
            />
        </Card>
    )
};

export default PODLiveTable;