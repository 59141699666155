import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Constants } from '../../common/utils/constants';
import * as actions from '../reports/ingest/action';
import ReportListing from "./report.listing";

var fetching = false;
var timeoutCall = undefined;

function ReportContainer(props) {
    const defaultFilters = {
        validFrom: moment().subtract(7, 'days').startOf('day'),
        validTo: moment().endOf('day'),
        documentStatuses: [
            { id: 0, name: "INITIATED", value: false, storedValue: "In Progress" },
            { id: 1, name: "COMPLETED", value: false, storedValue: "Completed" },
        ],

    };
    const defaultParams = {
        "userIds": [Constants.sessionUser.id],
        "pageNo": 1,
        "pageSize": 10,
        "documentStatuses": [],
        "createdAt": {
            "from": moment().subtract(7, 'days').startOf('day').valueOf(),
            "to": moment().endOf('day').valueOf()
        }
    }
    const [params, setParams] = useState({ ...defaultParams });
    const [filters, setFilters] = useState({ ...defaultFilters });
    const [visible, setVisible] = useState(false);
    const [filterIndicator, setIndicator] = useState(false);

    const reportReload = params => props.getReportListing(params);

    useEffect(_ => {
        reportReload(params)
    }, []);

    const applyFilters = () => {
        const stype = filters.documentStatuses.map(s => { if (s.value) return s.name });
        const newParams = {
            ...params,
            documentStatuses: _.compact(stype),
            "createdAt": {
                "from": filters.validFrom ? moment(filters.validFrom).valueOf() : "",
                "to": filters.validTo ? moment(filters.validTo).valueOf() : ""
            }
        }
        setParams(newParams);
        reportReload(newParams);
        setVisible(false);
        setIndicator(true);
    }

    const resetFilters = () => {
        setFilters({ ...defaultFilters })
        const newParams = {
            ...params,
            ...defaultParams
        }
        setParams(newParams);
        reportReload(newParams);
        setVisible(false);
        setIndicator(false);
    }

    return (
        <ReportListing
            reportReload={reportReload}
            setParams={updatedParams => {
                setParams(updatedParams);
                reportReload(updatedParams);
            }}
            applyFilters={applyFilters}
            resetFilters={resetFilters}
            filters={filters}
            setFilters={setFilters}
            visible={visible}
            setVisible={setVisible}
            filterIndicator={filterIndicator}
            {...props}
            params={params}
        />
    )
}

const mapStateToProps = state => {

    return {
        loading: state.ingest.listingLoading,
        reportListing: state.ingest.reportListing,
        documents: (state.ingest.reportListing && state.ingest.reportListing?.documents?.length > 0) ? state.ingest.reportListing?.documents : undefined
    }
};


const mapDispatchToProps = dispatch => ({
    getReportListing: params => dispatch(actions.getReportListing(params))
});
// export default Reports;
export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);