import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const ScHubVisibilityForward = () => {

    return (
        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">SC | Forward</div>

            <div className="flex-column flex-gap-xl">
                <MetabaseWidget text={"Bags & Shipments arriving"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 298, reportName: "bags-shipments-arriving",
                        lockedParams: ["location_id"], height: "300px"
                    }} />
                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"Received but not Bagged"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 419, reportName: "received-but-not-bagged",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"Bagged not Connected"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 401, reportName: "bagged-not-connected",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"Added to Trip but not Dispatched"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 313, reportName: "added-to-trip-but-not-dispatched",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                </div>
            </div>

            <div className="flex-column flex-gap-xl">

                <div
                    className="metabase-subheader-container font-size-l-1 border-radius-m">
                    General Data
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"Bags & Shipments Unloaded"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 300, reportName: "bags-shipments-unloaded",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                    <MetabaseWidget text={"Bags & Shipments Received"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 301, reportName: "bags-shipments-received",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                </div>

                <div className="flex-box flex-gap-m justify-content-center">
                    <MetabaseWidget text={"Bagging Complete"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 308, reportName: "bagging-complete",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                    <MetabaseWidget text={"Bags & Shipments Dispatched"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 302, reportName: "bags-shipments-dispatched",
                            lockedParams: ["location_id"], height: "400px"
                        }} />
                </div>

            </div>

        </div>
    )

}

export default ScHubVisibilityForward;