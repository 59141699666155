import React, { Component } from 'react';
import './scan-sticker.scss';
import moment from 'moment';
import TamperedReasonSelect from '../../common/tampered-reason-select';

class ScanSticker extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const { scanResponseStatus, scanResponseDataCurated, isBarcode } = this.props;

    return (
      <>
        {
          isBarcode == "barcodeEnabled" ?
            <div className="ScanSticker">
              <div className='flex-column'> 
              {
                scanResponseStatus?.success && scanResponseDataCurated?.waybillNo?.value && scanResponseDataCurated.clientMetaData &&
                (
                  scanResponseDataCurated.clientMetaData?.revSortCode || 
                  scanResponseDataCurated.clientMetaData.priorityTag
                ) ?
                <div className='flex-box flex-gap-m align-items-stretch spacer-vm'>
                  {
                    (scanResponseDataCurated.clientMetaData?.revSortCode) &&
                    <div className='flex-column flex-gap-xs align-items-center spacer-s border-radius-s'
                      style={{
                      color : scanResponseDataCurated.clientMetaData.isPriority ? "white" : "black",
                      backgroundColor : scanResponseDataCurated.clientMetaData.isPriority ? "red" : "white"
                      }}>
                      <div className='font-size-l-1 text-bold'> 
                        {scanResponseDataCurated.clientMetaData?.revSortCode || "GH12"} 
                      </div>
                      <div> Sort Code </div>
                    </div>
                  }
                  {
                    (scanResponseDataCurated.clientMetaData.priorityTag) &&
                    <div className='flex-column font-size-l-1 justify-content-center flex-gap-l align-items-center spacer-m border-radius-s'
                    style={{ 
                      color : scanResponseDataCurated.clientMetaData.priorityColor ? "white" : "black",
                      backgroundColor : scanResponseDataCurated.clientMetaData?.priorityColor || "white"
                    }}>
                      {scanResponseDataCurated.clientMetaData.priorityTag}
                    </div>
                  }
                </div>
                : <></>
              }
              {
                scanResponseStatus?.success && scanResponseDataCurated?.waybillNo?.value
                  ? <div className="scan-box">
                    <div className='flex-box justify-content-space-between'>
                      <ul>
                        {
                          (scanResponseDataCurated?.barcode?.value) ? <li>
                            <div className="key">{scanResponseDataCurated.barcode.key}</div>
                            <div className="value">{scanResponseDataCurated.barcode.value}</div>
                          </li> : null
                        }
                        {
                          (scanResponseDataCurated?.waybillNo?.value) ? <li className="waybill">
                            <div className="key">{scanResponseDataCurated.waybillNo.key}</div>
                            <div className="value">{scanResponseDataCurated.waybillNo.value}</div>
                          </li> : null
                        }
                        {
                          (scanResponseDataCurated?.cpd?.value && !scanResponseDataCurated.clientMetaData) ? <li>
                            <div className="key">{scanResponseDataCurated.cpd.key}</div>
                            <div className="value">{moment(scanResponseDataCurated.cpd.value).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(scanResponseDataCurated.cpd.value).fromNow()}</small></div>
                          </li> : null
                        }
                        {
                          (scanResponseDataCurated?.lastPodConStatusWithReason?.value) ? <li>
                            <div className="key">
                              {scanResponseDataCurated.lastPodConStatusWithReason.key}</div>
                            <div className="value">
                              {scanResponseDataCurated.lastPodConStatusWithReason.value}</div>
                          </li> : null
                        }
                        {
                            (scanResponseDataCurated?.batchNumber?.value) ? <li>
                              <div className="key">
                              {scanResponseDataCurated?.batchNumber?.key}</div>
                              <div className="value">
                                {scanResponseDataCurated?.batchNumber?.value}</div>
                            </li> : null
                          }
                        {
                          (scanResponseDataCurated?.destinationLocationName?.value) ? <li>
                            <div className="key">{scanResponseDataCurated.destinationLocationName.key}</div>
                            <div className="value pincode">{scanResponseDataCurated.destinationLocationName.value}</div>
                          </li> : null
                        }
                        {
                          (scanResponseDataCurated?.locationCode?.value) ? <li>
                            <div className="key">{scanResponseDataCurated.locationCode.key}</div>
                            <div className="value pincode">{scanResponseDataCurated.locationCode.value}</div>
                          </li> : null
                        }
                        {
                          (scanResponseDataCurated?.consigneeAddress?.value) ? <li>
                            <div className="key">{scanResponseDataCurated.consigneeAddress.key}</div>
                            <div className="value address">{scanResponseDataCurated.consigneeAddress.value}</div>
                          </li> : null
                        }
                        {
                          scanResponseDataCurated.clientMetaData &&
                          <>
                            {
                              scanResponseDataCurated.clientMetaData.instructionSummary && scanResponseDataCurated.clientMetaData.instructionSummary.length > 0 &&
                              <li>
                                <div className="key">Category</div>
                                <div className="value">{scanResponseDataCurated.clientMetaData.instructionSummary?.join(", ")}</div>
                              </li>
                            }
                            <li>
                              <div className="key">Expected Delivery Date</div>
                              <div className="value">{scanResponseDataCurated.clientMetaData.expectedDeliveryDate || "--"}</div>
                            </li>
                            <li>
                              <div className="key">Next OFD Date</div>
                              <div className="value">{scanResponseDataCurated.clientMetaData.nextOutForDelivery}</div>
                            </li>
                          </>
                        }
                      </ul>
                      <div>
                        {scanResponseDataCurated &&
                          <TamperedReasonSelect scanType={"INSCAN"}
                            customerId={scanResponseDataCurated?.consignmentScanBO?.customerId}
                            waybillNo={scanResponseDataCurated?.waybillNo?.value} />
                        }
                      </div>
                    </div>
                  </div>
                  : <div className="scan-box"></div>
              }
              </div>
            </div> :

            <div className="ScanSticker">
              <div className='flex-column'> 
              {
                scanResponseStatus?.success && scanResponseDataCurated?.waybillNo?.value && scanResponseDataCurated.clientMetaData &&
                (
                  scanResponseDataCurated.clientMetaData?.revSortCode || 
                  scanResponseDataCurated.clientMetaData.priorityTag
                ) ?
                <div className='flex-box flex-gap-m align-items-stretch spacer-vm'>
                  {
                    (scanResponseDataCurated.clientMetaData?.revSortCode) &&
                    <div className='flex-column flex-gap-xs align-items-center spacer-s border-radius-s'
                      style={{
                      color : scanResponseDataCurated.clientMetaData.isPriority ? "white" : "black",
                      backgroundColor : scanResponseDataCurated.clientMetaData.isPriority ? "red" : "white"
                      }}>
                      <div className='font-size-l-1 text-bold'> 
                        {scanResponseDataCurated.clientMetaData?.revSortCode || "GH12"} 
                      </div>
                      <div> Sort Code </div>
                    </div>
                  }
                  {
                    (scanResponseDataCurated.clientMetaData.priorityTag) &&
                    <div className='flex-column font-size-l-1 justify-content-center flex-gap-l align-items-center spacer-m border-radius-s'
                    style={{ 
                      color : scanResponseDataCurated.clientMetaData.priorityColor ? "white" : "black",
                      backgroundColor : scanResponseDataCurated.clientMetaData?.priorityColor || "white"
                    }}>
                      {scanResponseDataCurated.clientMetaData.priorityTag}
                    </div>
                  }
                </div>
                : <></>
              }
              {
                scanResponseStatus?.success ?
                  scanResponseDataCurated?.waybillNo?.value ?
                    <div className="scan-box">
                      <div className='flex-box justify-content-space-between'>
                        <ul>
                          {
                            (scanResponseDataCurated?.barcode?.value) ? <li>
                              <div className="key">{scanResponseDataCurated.barcode.key}</div>
                              <div className="value">{scanResponseDataCurated.barcode.value}</div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.overageStatus) &&
                            <li>
                              <div className="key">Overage Status</div>
                              <div className="value error-color text-semi-bold">{scanResponseDataCurated?.overageStatus}</div>
                            </li>
                          }
                          {
                            (scanResponseDataCurated?.locationCode?.value) ? <li>
                              <div className="key">{scanResponseDataCurated.locationCode.key}</div>
                              <div className="value pincode">{scanResponseDataCurated.locationCode.value}</div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.waybillNo?.value) ? <li className="waybill">
                              <div className="key">{scanResponseDataCurated.waybillNo.key}</div>
                              <div className="value">{scanResponseDataCurated.waybillNo.value}</div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.shipmentValue?.value) ? <li>
                              <div className="key">{scanResponseDataCurated.shipmentValue.key}</div>
                              <div className="value">
                                {
                                  (scanResponseDataCurated.shipmentValue.value >= 5000) && <i className="ti-arrow-up text-red"></i>
                                }
                                ₹ {scanResponseDataCurated.shipmentValue.value}
                              </div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.cpd?.value && !scanResponseDataCurated.clientMetaData) ? <li>
                              <div className="key">{scanResponseDataCurated.cpd.key}</div>
                              <div className="value">{moment(scanResponseDataCurated.cpd.value).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(scanResponseDataCurated.cpd.value).fromNow()}</small></div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.shippmentDay?.value) ? <li>
                              <div className="key">{scanResponseDataCurated.shippmentDay.key}</div>
                              <div className="value">{scanResponseDataCurated.shippmentDay.value} Days</div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.rescheduleDate?.value) ? <li>
                              <div className="key">{scanResponseDataCurated.rescheduleDate.key}</div>
                              <div className="value">{moment(scanResponseDataCurated.rescheduleDate.value).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(scanResponseDataCurated.rescheduleDate.value).fromNow()}</small></div>
                              {/* <div className="value">{scanResponseDataCurated.rescheduleDate.value | amDateFormat:'DD MMM YYYY'}</div> */}
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.isLocalServiceable?.value && !scanResponseDataCurated.clientMetaData) ? <li>
                              <div className="key">local Servicibility:</div>
                              <div className="value">{scanResponseDataCurated.isLocalServiceable.value}</div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.nextLocation?.value) ? <li>
                              <div className="key">{scanResponseDataCurated.nextLocation.key}</div>
                              <div className="value">{scanResponseDataCurated.nextLocation.value}</div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.lastAttemptDate?.value) ? <li>
                              <div className="key">
                                {scanResponseDataCurated.lastAttemptDate.key}
                              </div>
                              <div className="value">
                                {moment(scanResponseDataCurated.lastAttemptDate.value).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(scanResponseDataCurated.lastAttemptDate.value).fromNow()}</small>
                              </div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.attemptCount?.value) ? <li>
                              <div className="key" >
                                {scanResponseDataCurated.attemptCount.key}</div>
                              <div className="value">
                                {scanResponseDataCurated.attemptCount.value}</div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.lastPodConStatusWithReason?.value) ? <li>
                              <div className="key">
                                {scanResponseDataCurated.lastPodConStatusWithReason.key}</div>
                              <div className="value">
                                {scanResponseDataCurated.lastPodConStatusWithReason.value}</div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.batchNumber?.value) ? <li>
                              <div className="key">
                              {scanResponseDataCurated?.batchNumber?.key}</div>
                              <div className="value">
                                {scanResponseDataCurated?.batchNumber?.value}</div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.shipperName?.value) ? <li>
                              <div className="key">{scanResponseDataCurated.shipperName.key}</div>
                              <div className="value">{scanResponseDataCurated.shipperName.value}</div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.consigneeAddress?.value) ? <li>
                              <div className="key">{scanResponseDataCurated.consigneeAddress.key}</div>
                              <div className="value address">{scanResponseDataCurated.consigneeAddress.value}</div>
                            </li> : null
                          }
                          {
                            scanResponseDataCurated.clientMetaData &&
                            <>
                              {
                                scanResponseDataCurated.clientMetaData.instructionSummary && scanResponseDataCurated.clientMetaData.instructionSummary.length > 0 &&
                                <li>
                                  <div className="key">Category</div>
                                  <div className="value">{scanResponseDataCurated.clientMetaData.instructionSummary?.join(", ")}</div>
                                </li>
                              }
                              <li>
                                <div className="key">Expected Delivery Date</div>
                                <div className="value">{scanResponseDataCurated.clientMetaData.expectedDeliveryDate || "--"}</div>
                              </li>
                              <li>
                                <div className="key">Next OFD Date</div>
                                <div className="value">{scanResponseDataCurated.clientMetaData.nextOutForDelivery}</div>
                              </li>
                            </>
                          }
                        </ul>
                        <div>
                          {
                            scanResponseDataCurated &&
                            <TamperedReasonSelect scanType={"INSCAN"}
                              customerId={scanResponseDataCurated?.consignmentScanBO?.customerId}
                              waybillNo={scanResponseDataCurated?.waybillNo?.value} />
                          }
                        </div>
                      </div>

                      {
                        !scanResponseDataCurated.clientMetaData &&
                        <ul className="third-row-scan">
                          {
                            (scanResponseDataCurated?.primaryBucket?.value) ? <li>
                              <div className="key">{scanResponseDataCurated.primaryBucket.key}</div>
                              <div className={`value scan-bucket
                      ${(scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'CRITICAL' || scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'HIGH') ? 'background-warning' : ''}
                      ${scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'MEDIUM' ? 'background-medium' : ''}
                      ${(scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'NORMAL' || scanResponseDataCurated.primaryBucket.primaryBucketPriority == 'LOW') ? 'background-normal' : ''}
              `}>{scanResponseDataCurated.primaryBucket.value}</div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.secondaryBucket?.value) ? <li>
                              <div className="key">&nbsp;</div>
                              <div className={`value scan-bucket
                      ${(scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'CRITICAL' || scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'HIGH') ? 'background-warning' : ''}
                      ${scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'MEDIUM' ? 'background-medium' : ''}
                      ${(scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'NORMAL' || scanResponseDataCurated.secondaryBucket.secondaryBucketPriority == 'LOW') ? 'background-normal' : ''}
              `}>{scanResponseDataCurated.secondaryBucket.value}</div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated?.tertiaryBucket?.value) ? <li>
                              <div className="key">&nbsp;</div>
                              <div className={`value scan-bucket
                      ${(scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'CRITICAL' || scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'HIGH') ? 'background-warning' : ''}
                      ${scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'MEDIUM' ? 'background-medium' : ''}
                      ${(scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'NORMAL' || scanResponseDataCurated.tertiaryBucket.tertiaryBucketPriority == 'LOW') ? 'background-normal' : ''}
              `}>{scanResponseDataCurated.tertiaryBucket.value}</div>
                            </li> : null
                          }
                          {
                            (scanResponseDataCurated.isSuspiciousPod.value) ? <li>
                              <div className="key">&nbsp;</div>
                              <div className="value scan-bucket background-warning">
                                {scanResponseDataCurated.isSuspiciousPod.value ? 'SUSPICIOUS' : 'SUSPICIOUS'}</div>
                            </li> : null
                          }
                        </ul>
                      }
                    </div> :

                    scanResponseDataCurated?.overageStatus ?
                      <div className="scan-box">
                        <ul>
                          {
                            (scanResponseDataCurated?.awbNo) &&
                            <li>
                              <div className="key">Waybill Number</div>
                              <div className="value">{scanResponseDataCurated?.awbNo}</div>
                            </li>
                          }
                          {
                            (scanResponseDataCurated?.overageStatus) &&
                            <li>
                              <div className="key">Overage Status</div>
                              <div className="value pincode">{scanResponseDataCurated?.overageStatus}</div>
                            </li>
                          }
                        </ul>
                      </div> :
                      <div className="scan-box"></div> :
                  <div className="scan-box"></div>
              }
              </div>
            </div>
        }
      </>

    )
  }
}

export default ScanSticker;