import moment from "moment";

export const previewMapping = {
    "DRS_REPORT": "DRS_SUMMARY",
    "RTO_DRS_REPORT": "RTO_DRS_SUMMARY",
    "COD_COLLECTABLE_REPORT": "CASH_ON_DELIVERY_SUMMARY",
    // "CARD_ON_DELIVERY": "CARD_ON_DELIVERY_SUMMARY",
    "LAST_STATUS_REPORT": "CONSIGNMENT_SUMMARY",
    "BOOKING_REPORT": "CONSIGNMENT_SUMMARY",
    // "CRITICAL_SHIPMENTS": "CRITICAL_CONSIGNMENT_SUMMARY"
};

export const reportTypeLegacyArray = [
    { "value": 0, "label": "DRS", "code": "DRS_REPORT", "disabled": false },
    { "value": 1, "label": "COD", "code": "COD_COLLECTABLE_REPORT", "disabled": false },
    { "value": 2, "label": "Booking", "code": "BOOKING_REPORT", "disabled": false },
    { "value": 3, "label": "Last Status", "code": "LAST_STATUS_REPORT", "disabled": false },
    { "value": 4, "label": "RTO DRS Report", "code": "RTO_DRS_REPORT", "disabled": false },
    // { "value": 5, "label": "Card On Delivery", "code": "CARD_ON_DELIVERY", "disabled": false },
    // { "value": 6, "label": "Critical Shipments", "code": "CRITICAL_SHIPMENTS", "disabled": false },
    // { "value": 7, "label": "Customer Delivery (Atlas Lite)", "code": "CUSTOMER_DELIVERY_REPORT_FUTURE", "disabled": false },
    // { "value": 8, "label": "THC Summary Report", "code": "THC_SUMMARY_REPORT", "disabled": false },
    // { "value": 9, "label": "User Performance Lite", "code": "USER_PERFORMANCE_LITE", "disabled": false },
    // { "value": 10, "label": "Download POD Zip File", "code": "CONSIGNMENT_POD", "disabled": false },
    { "value": 11, "label": "COD Pending Report", "code": "COD_IN_PENDING_REPORT", "disabled": Number(localStorage.getItem("loggedPartnerId")) !== 268 },
    // { "value": 12, "label": "Manifest Sheet Lite Bulk", "code": "MANIFEST_SHEET_LITE_BULK", "disabled": false },
    { "value": 13, "label": "Consignment POD By Waybill", "code": "CONSIGNMENT_POD_BY_WAYBILL", "disabled": false },
    // { "value": 14, "label": "Critical Shipments V2", "code": "CRITICAL_SHIPMENTS_REPORT_V2", "disabled": false },
    // { "value": 15, "label": "Overage Summary Report", "code": "OVERAGE_SUMMARY_REPORT", "disabled": false },
    // { "value": 16, "label": "Pickup Pending Report", "code": "PICKUP_PENDING_REPORT", "disabled": true },
    { "value": 17, "label": "Shipment Scan Report", "code": "SHIPMENT_SCAN_REPORT", "disabled": false },
    { "value": 18, "label": "RTO Eligible Consignment Report", "code": "RTO_ELIGIBLE_CONSIGNMENT_REPORT" },
    { "value": 19, "label": "Manifest Report", "code": "MANIFEST_REPORT" },
    { "value": 20, "label": "Trip Summary Report", "code": "TRIP_SUMMARY_REPORT" },
]

export const generateReportConfig = () => {
    var initTemp = {
        "downloadTypeLabel": ["By Date", "By Waybill Upload", "By Pickup Date"],
        "downloadType": ["date", "waybill", "pickupdate"],
        "formFields": {
            "date"       : ["dateRange", "partners", "destinations", "customers" ],
            "waybill"    : ["waybill"],
            "trip"       : ["trip"],
            "pickupdate" : ["dateRange", "partners", "destinations", "customers" ],
        },
        "postObjReference": {
            "dateRange"    : "dateRange",
            "partners"     : "partnerIds",
            "destinations" : "destinationLocationIds",
            "customers"    : "customerIds",
            "waybill"      : "s3FileLink",
            "employees"    : "userIds"
        },
        "submitOptions": [
            {
              "id": "greport",
              "endPoint": {
                  "url": "/b2b/v1/reports/XXXX/get",
                  "method": "POST"
              },
              "action": "generateReport",
              "buttonClass": "lsn-btn-primary",
              "buttonText": "Generate Report"
            },
            {
              "id": "preview",
              "endPoint": {
                  "url": "/b2b/v1/reports/XXXX/get",
                  "method": "POST"
              },
              "action": "previewReport",
              "buttonClass": "lsn-btn-secondary",
              "buttonText": "Preview",
              "additionalPostAttributes" : {
                "onlyData"   : true
              }
            }
        ],
        "required": ["dateRange", "partners", "destinations", "customers", "waybill" ],
        "additionalPostAttributes": {}
    }

    var reportsArray = [];

    reportTypeLegacyArray.map(rep => {
        if (rep.value === 11 && localStorage.getItem("loggedPartnerId") != 268) return;
        var temp = JSON.parse(JSON.stringify(initTemp));
        if ([11,13,16].indexOf(rep.value) != -1) {
            delete temp.downloadTypeLabel;
            delete temp.downloadType;
            if (rep.value === 13) {
                temp.formFields = {
                    default : ["waybill"]
                };
            }
            if (rep.value === 11) {
                temp.formFields = {
                    default : []
                };
            }
            if (rep.value === 16) {
                temp.formFields = {
                    default : ["partners", "destinations", "customers"]
                };
                temp.additionalPostAttributes = {
                    commCode :  "PICKUP_PENDING_REPORT"
                }
            }
        }
        if (rep.value == 20) {
            temp.formFields.date = ["dateRange", "partners", "destinations"]
            temp.formFields.pickupdate = ["dateRange", "partners", "destinations"]
            temp.formFields.required = ["dateRange", "partners", "destinations", "waybill" ]
            delete temp.postObjReference.customers
        }
        if ([10,12,20].indexOf(rep.value) != -1 && temp.downloadTypeLabel) {
            temp.downloadTypeLabel = temp.downloadTypeLabel.filter(a => a !== "By Waybill Upload")
            temp.downloadType = temp.downloadType.filter(a => a !== "waybill")
        }
        if (rep.value === 10) {
            temp.additionalPostAttributes = {
                appendCustomer : true
            }
            temp.postObjReference.destinations = "locationIds";
        }
        if ([7,21].indexOf(rep.value) == -1 && temp.downloadTypeLabel) {
            temp.downloadTypeLabel = temp.downloadTypeLabel.filter(a => a != "By Pickup Date")
            temp.downloadType = temp.downloadType.filter(a => a != "pickupdate")
        }
        else {
            temp.additionalPostAttributesByDownloadType = {
                pickupdate : {
                    isDatePickup : true
                }
            }
        }
        if (rep.value == 16 && temp.formFields.date) {
            temp.formFields.date = temp.formFields.date.filter(f => f != "date")
        }
        if (rep.value == 15 || rep.value == 19 && temp.formFields.date) {
            temp.formFields.date = temp.formFields.date.filter(f => f != "customer")
        }
        if (rep.value == 14 && temp.formFields.date) {
            temp.required = temp.required.filter(x => x != "dateRange");
        }
        if( [12,13,10].indexOf(rep.value) != -1 ) {
            temp.submitOptions.map(submit => {
                submit.endPoint.url = submit.endPoint.url.replace("reports","document");
                submit.endPoint.url = submit.endPoint.url.replace("get","download");
            })
        }
        if (rep.value === 12) {
            temp.additionalPostAttributes = {
                appendCustomer : false
            };
        }
        if ([12, 18].indexOf(rep.value) != -1) {
            temp.postObjReference.destinations = "locationIds";
        }
        if (rep.value === 11) {
            temp.additionalPostAttributes = {
                "depositStatus" : [ "PENDING", "PARTIAL_APPROVE", "REQUEST_FOR_APPROVAL" ],
                "customerIds"   : [ 268 ],
                "dateRange"     : {
                  "from" : null,
                  "to"   : moment().endOf("day").valueOf(),
                },
                "codViewType"       : "CUSTOMER",
                "consolidationType" : "PARTNER"
            };
        }
        if (rep.value === 0) {
            temp.formFields.date.push("employees");
        }

        temp.submitOptions = temp.submitOptions.map(submit => {
            if (submit.id === "greport") {
                submit.endPoint.url = submit.endPoint.url.replace("XXXX",rep.code)
            }
            else if (submit.id === "preview" && previewMapping[rep.code]) {
                submit.endPoint.url = submit.endPoint.url.replace("XXXX", previewMapping[rep.code] || rep.code)
            }
            if ([10, 12, 13].indexOf(rep.value) != -1 ) {
                submit.endPoint.url = submit.endPoint.url.replace("reports", "document");
                submit.endPoint.url = submit.endPoint.url.replace("get", "download");
            }
            return submit
        }).filter(submit => submit.endPoint.url.indexOf("XXXX") < 0)
        reportsArray.push({
            metaInfo : temp,
            ...rep
        })
    })

    return reportsArray;
}