import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const NdrInstruction = () => {

    return (
        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">NDR Instruction Dashboard</div>

            <div className="flex-column flex-gap-l">
                <MetabaseWidget text={"NDR Instruction Classification"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 3714, reportName: "ndr-instruction-classification",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

                <MetabaseWidget text={"Log10 - NDR Instruction received waybills"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 3712, reportName: "log10-ndr-instruction-received-waybills",
                        lockedParams: ["location_id"], height: "400px"
                    }} />

            </div>
        </div>
    )

}

export default NdrInstruction;