import { Button, Modal, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConnectionsMenu from "./connections.menu";
import { deleteConnection, deleteConnectionFromSheet, fetchConnectionDetails, fetchConnectionSheets, fetchShipmentCountByConnections } from "./connections.service";
import LoaderService from "../../../../services/loader";


export default function ConnectionsSheetV2() {

    const defaultFilterPayload = {
        pageNo: 1,
        pageSize: 10,
        startDate: moment().subtract(15, "days").startOf().valueOf(),
        endDate: moment().startOf().valueOf(),
        connectionStatuses: ["PENDING"]
    }

    const navigate = useNavigate();

    const [loading, SetLoading] = useState(true);
    const [connectionsResponse, SetConnectionsResponse] = useState(undefined);
    const [fetchConnectionsPayload, SetFetchConnectionsPayload] = useState({ ...defaultFilterPayload });
    const appConfig = useSelector(state => state?.app?.configurations)

    const callFetchConnections = payload => {
        SetLoading(true);
        if (appConfig?.enableRearch?.enableConnectionReadOps == "true") {
            fetchConnectionDetails(
                payload,
                { showLoader: false }
            )
                .then(response => {
                    SetConnectionsResponse(response);
                    SetLoading(false);
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    })
                })
                .catch(e => {
                    console.log("Error while fetching connection list", e)
                    SetLoading(false);
                }
                )
        }
        else {
            LoaderService.toggleTabLoaderOn()
            fetchConnectionSheets(payload)
                .then(response => {
                    SetConnectionsResponse(response);
                    SetLoading(false);
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    })
                    if (response?.connectionSheets && response?.connectionSheets?.length > 0) {
                        fetchShipmentCountByConnections({
                            connectionIds: response?.connectionSheets?.map(c => c.connectionId)
                        })
                            .then(shipmentCountResponse => {
                                SetConnectionsResponse(current => {
                                    var __connectionResponse = { ...current };
                                    __connectionResponse.connectionSheets = __connectionResponse.connectionSheets.map(conn => {
                                        var countResponseConnection = shipmentCountResponse?.connectionSheets?.find(c => c.connectionId == conn.connectionId);
                                        if (countResponseConnection) {
                                            conn = {
                                                ...conn,
                                                manifestCount: countResponseConnection.manifestCount,
                                                shipmentCount: countResponseConnection.shipmentCount
                                            }

                                        }
                                        return conn;
                                    })
                                    return __connectionResponse;
                                })
                            })
                            .catch(e => console.log("2", e))
                    }
                    LoaderService.toggleTabLoaderOff()
                })
                .catch(e => {
                    console.log("1", e)
                    LoaderService.toggleTabLoaderOff()
                })
        }

    };

    const manifestPrint = rowData => {
        const url = `/appv2/print_information?consignmentId=${rowData.connectionId}&formtype=connectionSheet`;
        window.open(url, "_blank");
    };

    const handleDelete = (rowData) => {
        Modal.confirm({
            title: 'Delete Connection',
            content: 'Are you sure you want to delete this connection?',
            onOk: _ => {
                if (appConfig?.enableRearch?.enableConnectionWriteOps == "true") {
                    deleteConnectionFromSheet(rowData?.connectionCode)
                        .then(_ => {
                            callFetchConnections(fetchConnectionsPayload);
                            Modal.destroyAll()
                        })
                        .catch(_ => Modal.destroyAll())
                }
                else {
                    deleteConnection(rowData?.connectionId)
                        .then(_ => {
                            callFetchConnections(fetchConnectionsPayload);
                            Modal.destroyAll()
                        })
                        .catch(_ => Modal.destroyAll())
                }
            },
            okText: "Yes",
            cancelText: "No"
        })
    }

    const columns = [
        {
            title: "Connection ID",
            dataIndex: "connectionCode",
            key: "connectionCode"
        },
        {
            title: "Destination",
            dataIndex: "destinationLocation",
            key: "destinationLocation"
        },
        {
            title: "Manifests",
            dataIndex: "manifestCount",
            key: "manifestCount",
            render: (data) => {
                if (data !== undefined) {
                    return <>{data}</>
                }
                else {
                    return (
                        <div className="flex-box align-items-center justify-content-center">
                            <div className="spinning-loader"></div>
                        </div>
                    )
                }
            }
        },
        {
            title: "Shipments",
            dataIndex: "shipmentCount",
            key: "shipmentCount",
            render: (data) => {
                if (data !== undefined) {
                    return <>{data}</>
                }
                else {
                    return (
                        <div className="flex-box align-items-center justify-content-center">
                            <div className="spinning-loader"></div>
                        </div>
                    )
                }
            }
        },
        {
            title: "Created On",
            dataIndex: "connectionCreatedDate",
            key: "connectionCreatedDate",
            render: (data) => data ? moment(data).format("YYYY-MM-DD H:mm") : <>--</>
        },
        {
            title: "Status",
            dataIndex: "connectionStatus",
            key: "connectionStatus"
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (data, row) => {
                return (
                    <div className="flex-box justify-content-flex-start flex-gap-s">
                        {
                            row && row.connectionStatus && row.connectionStatus === "PENDING" && (
                                <Button className="lsn-btn-primary" type="primary" icon="edit"
                                    size="small" onClick={() => navigate(`/appv2/trips/form/connection-sheet/${row.connectionCode}`)} />
                            )
                        }
                        <Button type="default" icon="printer" size="small" onClick={_ => manifestPrint(row, data)} className="lsn-btn-secondary" />
                        {row && "connectionStatus" in row && row.connectionStatus === "PENDING" && "manifestCount" in row && row.manifestCount == 0 &&
                            <Button type="default" icon="delete" size="small" className="lsn-btn-error" onClick={() => handleDelete(row)} />
                        }
                    </div>
                )
            }
        }
    ];

    const onPaginationChange = pageNo => {
        SetFetchConnectionsPayload(current => {
            return {
                ...current,
                pageNo
            }
        });
    }

    useEffect(_ => {
        if (fetchConnectionsPayload) {
            callFetchConnections(fetchConnectionsPayload);
        }
    }, [fetchConnectionsPayload])


    return (
        <div className="white-bg border-grey spacer-l flex-column flex-gap-l align-items-stretch">
            <ConnectionsMenu loading={loading} fetchConnectionsPayload={fetchConnectionsPayload} defaultFilterPayload={defaultFilterPayload}
                SetFetchConnectionsPayload={SetFetchConnectionsPayload} />
            <Table loading={loading} columns={columns} dataSource={connectionsResponse?.connectionSheets}
                bordered={true} scroll={{ x: true }} rowKey={"connectionId"}
                pagination={{
                    total: connectionsResponse?.totalCount,
                    pageSize: connectionsResponse?.currentPageSize,
                    current: connectionsResponse?.currentPageNo,
                    onChange: onPaginationChange
                }}
            />
        </div>
    )
}