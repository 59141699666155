import React, { useEffect, useState } from "react";
import DRSBasic from "./basic";
import DRSDetailed from "./detailed";



function DRSClosure(props) {

    const [userId, setUserId] = useState(undefined);
    const [scanned, setScanner] = useState(false);
    const [drsId, setDrsId] = useState(undefined);
    const [showCloseDrs, setCloseDrs] = useState(undefined);
    const [userInfo, setuserInfo] = useState(undefined);
    const [drsSummary, setDrsSummary] = useState({});
    const [drsStatus, setDrsStatus] = useState();

    return (
        <div className="flex-column flex-gap-xl spacer-m">
            <DRSBasic
                setUserId={setUserId}
                userId={userId}
                scanned={scanned}
                setScanner={setScanner}
                drsId={drsId}
                setCloseDrs={setCloseDrs} // Used to access close DRS modal
                setuserInfo={setuserInfo}
                drsSummary={drsSummary}
                drsStatus={drsStatus}
            />
            <DRSDetailed
                userId={userId}
                setDrsId={setDrsId}
                drsId={drsId}
                setCloseDrs={setCloseDrs}
                showCloseDrs={showCloseDrs}
                userInfo={userInfo}
                setDrsSummary={setDrsSummary}
                setDrsStatus={setDrsStatus}
            />

        </div>

    )
}

export default DRSClosure;