import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const ReverseImageAudit = () => {

    return (
        <div className="flex-column flex-gap-xl" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">RVP Success Image Audit</div>

            <MetabaseWidget text={"RVP success image audit"}
                className={"flex-1 flex-box justify-content-center"}
                reportJson={{
                    questionNo: 3568, reportName: "rvp-success-image-audit",
                    lockedParams: ["location_alias"], height: "400px"
                }} />

        </div>
    )

}

export default ReverseImageAudit;