import moment from "moment";

export const billingQueryReference = {
    SC_FORWARD: {
        reportJson: {
            dashboardNo: 226,
            reportName: "meesho-billing-mm-new-format",
            lockedParams: ["location_id", "partner_id"],
            customParams: {
                month: moment().subtract(1, 'month').format("MMMM")
            },
            height: "400px"
        },
        text: "Sort Center Billing Forward"
    },
    SC_RTO: {
        reportJson: {
            questionNo: 1726,
            reportName: "meesho-billing-mm-new-format-rto",
            lockedParams: ["location_id", "partner_id"],
            customParams: {
                scan_type: "RTO_IN",
                flow_type: "RTO"
            },
            height: "400px"
        },
        text: "Sort Center Billing RTO"
    },
    SC_CROSSDOCK: {
        reportJson: {
            questionNo: 1730,
            reportName: "meesho-billing-cross-dock",
            lockedParams: ["location_id"],
            height: "400px"
        },
        text: "Sort Center Billing Cross dock"
    },
    LM_FORWARD: {
        reportJson: {
            questionNo: 1725,
            reportName: "meesho-billing-lm-new-format",
            lockedParams: ["location_id"],
            height: "400px"
        },
        text: "Meesho - Billing - LM Forward"
    },
    LM_RTO: {
        reportJson: {
            questionNo: 1732,
            reportName: "meesho-billing-lm-rto-new-format",
            lockedParams: ["location_id"],
            height: "400px"
        },
        text: "Meesho - Billing - LM RTO"
    },
    FM_FORWARD: {
        reportJson: {
            questionNo: 1840,
            reportName: "meesho-billing-fm-forward-new-format",
            lockedParams: ["location_id"],
            height: "400px"
        },
        text: "Meesho - Billing - FM Forward"
    },
    FM_RTO: {
        reportJson: {
            questionNo: 1841,
            reportName: "meesho-billing-fm-rto-new-format",
            lockedParams: ["location_id"],
            height: "400px"
        },
        text: "Meesho - Billing - FM RTO"
    },
    RVP_RIDER_WISE: {
        reportJson: {
            questionNo: 3155,
            reportName: "rvp-rider-wise-billing-data",
            lockedParams: ["location_id", "partner_id"],
            height: "400px"
        },
        text: "RVP Rider wise Billing Data"
    }
}