import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Spin, Radio } from "antd";

import {
    fetchEmbeddedUrl,
    fetchEmbedUrlWithProperties,
    getParsedEmbedUrl,
    getQueryParamsObj,
    construcFetchEmbedUrlPayload
} from "./action";

import "./monitoringReport.styles.scss";
import { MONITOR_REPORTS_CONFIG } from "./constants";
import RadioGroup from "antd/lib/radio/group";
import { pModules } from "../../configs/permissionTags";
import AccessDenied from "../authentication/access/AccessDenied";
import LastMileMetabaseDashboard from "../metabase-dashboards/last-mile";
import RVPGlobalPendencyPartnerLvlDashboard from "../metabase-dashboards/last-mile/rvp-global-pendency-partner-lvl";
import SortCenterVisibilityDashboard from "../metabase-dashboards/sort-center/sort-center-visibility";
import LastMileForward from "../metabase-dashboards/last-mile/forward";
import FirstMileMetabaseDashboard from "../metabase-dashboards/first-mile";
import ScHubVisibilityOverviewDashboard from "../metabase-dashboards/sort-center/sc-hub-visibility-overview";
import ScOverviewForward from "../metabase-dashboards/sort-center/sc-overview-forward";
import ScOverviewRto from "../metabase-dashboards/sort-center/sc-overview-rto";
import LastMileRto from "../metabase-dashboards/last-mile/rto";
import NdrInstructionDashboard from "../metabase-dashboards/last-mile/ndr-instruction";
import LastMileExceptions from "../metabase-dashboards/last-mile/Exceptions";
import ScHubVisibilityForward from "../metabase-dashboards/sort-center/ScHubVisibilityForward";
import ScHubVisibilityRto from "../metabase-dashboards/sort-center/ScHubVisibilityRto";
import ScHubVisibilityExceptions from "../metabase-dashboards/sort-center/ScHubVisibilityExceptions";
import ScVisibilityForward from "../metabase-dashboards/sort-center/ScVisibilityForward";
import ScVisibilityRto from "../metabase-dashboards/sort-center/ScVisibilityRto";
import ScVisibilityExceptions from "../metabase-dashboards/sort-center/ScVisibilityExceptions";

const MonitoringReportPage = ({
    embedUrl,
    fetchEmbeddedUrl,
    loading,
    match,
    location,
    fetchEmbedUrlWithProperties,
    getQueryParamsObj,
    construcFetchEmbedUrlPayload,
    history,
    customReportName
}) => {

    const loadQuestions = {
        "last-mile": { componentName: <LastMileMetabaseDashboard /> },
        "rvp-global-pendency-dashboard-partner-level": { componentName: <RVPGlobalPendencyPartnerLvlDashboard /> },
        "sort-center-visibility-overview": { componentName: <SortCenterVisibilityDashboard /> },
        "lm-forward": { componentName: <LastMileForward /> },
        "first-mile": { componentName: <FirstMileMetabaseDashboard /> },
        "mid-mile": { componentName: <ScHubVisibilityOverviewDashboard /> },
        "sc-overview-forward": { componentName: <ScOverviewForward /> },
        "sc-overview-rto": { componentName: <ScOverviewRto /> },
        "lm-rto": { componentName: <LastMileRto /> },
        "ndr-instruction-dashboard": { componentName: <NdrInstructionDashboard /> },
        "lm-exceptions": { componentName: <LastMileExceptions /> },
        "sc-forward": { componentName: <ScHubVisibilityForward /> },
        "sc-rto": { componentName: <ScHubVisibilityRto /> },
        "sc-exceptions": { componentName: <ScHubVisibilityExceptions /> },
        "sort-center-visibility-forward": { componentName: <ScVisibilityForward /> },
        "sort-center-visibility-rto": { componentName: <ScVisibilityRto /> },
        "sort-center-visibility-exceptions": { componentName: <ScVisibilityExceptions /> }
    }

    const [subTabs, SetSubTabs] = useState(undefined);
    const [tabKey, SetTabKey] = useState(undefined);
    const [urlParams, SetUrlParams] = useState(undefined)
    const [embedurl, SetEmbedurl] = useState(embedUrl);

    const config = useSelector(state => state.app?.configurations);

    const fetchEmbedUrlWithPropertiesFn = () => {
        let payload = null;
        const queryParams = location?.search ? new URLSearchParams(location.search) : null;

        if (queryParams) {
            if (queryParams.size == 1 && queryParams.has("date_params")) {
                const { params } = match;
                let reportName = params && params.reportName ? params.reportName : "";
                payload = {
                    reportName: reportName,
                    embedUrlGeneric: {
                        params: queryParams.get("date_params").split(",")
                    }
                };
            } else {
                const queryParamsArray = getQueryParamsObj(queryParams);
                if (queryParamsArray.length > 0) {
                    payload = construcFetchEmbedUrlPayload(reportName, queryParamsArray);
                }
            }
        }


        return payload;
    }

    useEffect(() => {
        let newUrl = embedUrl;
        let params = ""
        if (newUrl && newUrl.length > 0) {
            const formattedDate = new Date().toISOString().split('T')[0]
            const [baseUrl, hashStringFormat] = embedUrl.split('#')
            if (urlParams != null) {
                if (urlParams.includes("date")) { params += `&date=${formattedDate}` }
                if (urlParams.includes("start_date")) { params += `&start_date=${formattedDate}` }
                if (urlParams.includes("end_date")) { params += `&end_date=${formattedDate}` }
            }
            newUrl = `${baseUrl}${params != null ? '?' + params : params}${hashStringFormat ? "#" + hashStringFormat : ""}`

            SetEmbedurl(newUrl);
        }
    }), [urlParams]

    useEffect(() => {
        SetSubTabs(undefined);
        SetTabKey(undefined);

        const properties = fetchEmbedUrlWithPropertiesFn();
        if (properties && !properties?.embedUrlGeneric.resource == null) {
            fetchEmbedUrlWithProperties(properties)
        } else {
            const params = match?.params || null;
            let reportName = params && params.reportName ? params.reportName : "";
            if (customReportName) {
                reportName = customReportName
            }
            if (reportName === "sort-center-visibility" && config?.enableSCVisibilityV2 !== 'true') {
                return undefined;
            }
            if (MONITOR_REPORTS_CONFIG[reportName]) {
                if (MONITOR_REPORTS_CONFIG[reportName].tabs && MONITOR_REPORTS_CONFIG[reportName].tabs.length > 0) {
                    SetSubTabs(MONITOR_REPORTS_CONFIG[reportName].tabs);
                    reportName = MONITOR_REPORTS_CONFIG[reportName].tabs[0].reportName;
                    SetTabKey(reportName);
                    if (loadQuestions[reportName]) {
                        return
                    }
                }
            }
            else if (loadQuestions[reportName]) {
                SetTabKey(reportName);
                return
            }
            if (properties?.embedUrlGeneric?.params.includes("start_date")
                || properties?.embedUrlGeneric?.params.includes("date")) {
                SetUrlParams(properties?.embedUrlGeneric?.params)
            }
            fetchEmbeddedUrl(reportName);
        }

    }, [location?.pathname])

    const onTabChange = key => {

        if (!loadQuestions[key]) {
            fetchEmbeddedUrl(key);
            const { params } = match;
            let reportName = params && params.reportName ? params.reportName : "";
            let reportList = Object.values(MONITOR_REPORTS_CONFIG[reportName])[0]
            reportList.forEach(report => {
                if (report?.reportName === key && report?.params) {
                    SetUrlParams(report?.params.split(","));
                }
            })
        }
        SetTabKey(key);
    }

    if (match?.params?.reportName === "sort-center-visibility" && config?.enableSCVisibilityV2 !== 'true') {
        return <AccessDenied history={history} />;
    }

    console.log("tabKey", tabKey)

    return (
        <div className="iframe-container">
            {
                subTabs &&
                <div className="flex-box flex-gap-l align-items-center" style={{ marginBottom: 15 }}>
                    <RadioGroup buttonStyle="solid" onChange={event => onTabChange(event?.target?.value)} value={tabKey} >
                        {
                            subTabs.map(t => <Radio.Button className="report-preview-radio" value={t.reportName}> {t.label} </Radio.Button>)
                        }
                    </RadioGroup>
                </div>
            }
            {
                loading ?
                    <Spin className="spinner-class" /> :
                    loadQuestions[tabKey] ? loadQuestions[tabKey].componentName :
                        <iframe src={getParsedEmbedUrl(embedurl)} frameBorder={0} width="100%"
                            style={{
                                height: "calc(100vh - 180px)"
                            }} />
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    embedUrl: state.monitoringService.embedUrl,
    loading: state.monitoringService.loading,
    defaultReportName: ownProps.defaultReportName
});

const mapDispatchToProps = dispatch => ({
    fetchEmbeddedUrl: (reportName) => dispatch(fetchEmbeddedUrl(reportName)),
    fetchEmbedUrlWithProperties: (payload) => dispatch(fetchEmbedUrlWithProperties(payload)),
    construcFetchEmbedUrlPayload: (reportName, metadata) => construcFetchEmbedUrlPayload(reportName, metadata),
    getQueryParamsObj: (params) => getQueryParamsObj(params)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MonitoringReportPage);