import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const LastMileRto = () => {

    return (
        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">LM | RTO</div>

            <div className="flex-column flex-gap-l">
                <div className="font-size-m-4 grey-text">RTO Topline for Today - Location Level</div>

                <div className="flex-box flex-gap-m justify-content-center">

                    <MetabaseWidget text={"LM - RTO, not Bagged"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1022, reportName: "lm-rto-not-bagged",
                            lockedParams: ["location_id", "partner_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - Bagged, not Dispatched"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 961, reportName: "lm-bagged-not-dispatched",
                            lockedParams: ["location_id", "partner_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - RTO Dispatched, not Reached"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1024, reportName: "lm-rto-dispatched-not-reached",
                            lockedParams: ["location_id", "partner_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - RTO Initiated Today"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 965, reportName: "lm-rto-initiated-today",
                            lockedParams: ["location_id"], height: "300px"
                        }} />
                    <MetabaseWidget text={"LM - RTO Dispatched Today"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 966, reportName: "lm-rto-dispatched-today",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                </div>

                <div className="flex-box flex-gap-m justify-content-center">

                    <MetabaseWidget text={"LM - Dispatch Pendency - Total Count"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 964, reportName: "lm-dispatch-pendency-total-count",
                            lockedParams: ["location_id", "partner_id"], height: "300px"
                        }} />

                    <MetabaseWidget text={"LM - Dispatch Pendency"}
                        className={"flex-3 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 963, reportName: "lm-dispatch-pendency",
                            lockedParams: ["location_id"], height: "300px"
                        }} />

                </div>

                <div className="font-size-m-4 grey-text">RTO Pendency Data Dump - Location Level</div>

                <div className="flex-box flex-gap-m justify-content-center">

                    <MetabaseWidget text={"LM - RTO Pendency Data Dump"}
                        className={"flex-1 flex-box justify-content-center"}
                        reportJson={{
                            questionNo: 1018, reportName: "lm-rto-pendency-data-dump",
                            lockedParams: ["location_id", "partner_id"], height: "400px"
                        }} />

                </div>


            </div>

        </div>
    )

}

export default LastMileRto;