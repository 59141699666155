import React from "react";
import MetabaseWidget from "../components/metabase.widget";

const ScHubVisibilityExceptions = () => {
    return (
        <div className="flex-column flex-gap-l" style={{ paddingBottom: 24 }}>

            <div className="font-size-l-3 grey-text">SC | Exceptions</div>

            <div className="flex-column flex-gap-xl">
                <MetabaseWidget text={"Shortage and Excess Shipments (Forward & RTO)"}
                    className={"flex-1 flex-box justify-content-center"}
                    reportJson={{
                        questionNo: 335, reportName: "shortage-and-excess-shipments-forward-rto",
                        lockedParams: ["location_id"], height: "400px"
                    }}
                />
            </div>

        </div>
    )
}

export default ScHubVisibilityExceptions;